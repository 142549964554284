import { PricingGridProps, usePricingGridApi } from '@dierbergs-markets/react-feature-library';
import { IContract, IPricing } from '../../../../../../models';
import { useApplicationContextState } from '../../../../../../contexts/ApplicationContext';
import { useMemo } from 'react';
import { Store } from '@dierbergs-markets/react-component-library';
import { ValidationScopes } from '../../../../../../models/enums';
import { useContractValidationStore } from '../../../../../../store/contract/contractValidationStore';
import useContractHubPricingGridRowsAndColumns from './useContractHubPricingGridRowsAndColumns';
import { usePricingGridApiProps } from './usePricingGridApiProps';

/**
 * Configuration props for the useContractHubPricingGridSetup hook
 */
export interface ContractHubPricingGridSetupProps {
  /** The contract to generate pricing configuration for */
  contract?: IContract;
  /** Whether pricing data can be modified */
  canModify: boolean;
  /** Optional callback when pricing is edited */
  invokePricingEdit?: (pricing: IPricing) => void;
  /** Optional callback when pricing column changes occur */
  updatePricing?: (pricingIndex: number, pricing: IPricing) => void;
}

/**
 * A hook that provides configuration and validation for the PricingGrid component.
 * This hook serves two main purposes:
 * 1. It provides the necessary props for the PricingGrid component
 * 2. It exposes validation functionality that can be used independently of the grid UI
 *
 * @param props - Configuration options for pricing grid
 * @returns An object containing:
 *  - basePricingGridProps: Props required by the PricingGrid component
 *  - runInternalPricingGridValidation: Function to validate pricing data without requiring grid mounting
 */
export default function useContractHubPricingGridSetup(props: ContractHubPricingGridSetupProps) {
  const { referenceData } = useApplicationContextState();
  const { validationIssues } = useContractValidationStore(); // Get all validation issues

  const { contract, canModify, invokePricingEdit, updatePricing } = props;

  // Filter validation issues to only include PricingConfigurations scope
  const pricingConfigurationValidationIssues = useMemo(() => {
    return validationIssues.filter((issue) => issue.scope === ValidationScopes.PricingConfigurations);
  }, [validationIssues]);

  const { pricingColumnConfigurations, pricingItems } = useContractHubPricingGridRowsAndColumns(contract, referenceData, {
    canModify,
    invokePricingEdit,
    updatePricing,
    pricingConfigurationValidationIssues,
  });

  const pricingGridApiProps = usePricingGridApiProps(contract, { pricingColumnConfigurations });

  const { runInternalPricingGridValidation } = usePricingGridApi(pricingGridApiProps);

  const stores: Store[] = [];
  if (referenceData?.stores?.all) {
    stores.push(...referenceData.stores.all.map((s) => ({ number: s.id, name: s.displayName.trim() })));
  }

  const basePricingGridProps: PricingGridProps = {
    id: '',
    rows: pricingItems,
    pricingColumnConfigurations,
    referenceData: { stores },
  };

  return {
    basePricingGridProps,
    runInternalPricingGridValidation,
  };
}
