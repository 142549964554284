// zustand: https://github.com/pmndrs/zustand
// See more information on how to use it in StateManagement.mdx in the react-component-library

import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

// Could refactor to use immer, currently not needed as this state is passing in the whole obj
// See the StateManagement.mdx document in the react-component-library for more information
// import { immer } from 'zustand/middleware/immer';

import { IContractTimeFrame, IAdvSearch } from '../models';
import dayjs from 'dayjs';
import { DataGridFilterField } from '@dierbergs-markets/react-component-library';
import { SearchGridTypes } from '../models/enums';

export interface ContractsGridLocalState {
  advSearchInputs: IAdvSearch;
  dataGridFilters?: DataGridFilterField[];
  setDataGridFilters: (value?: DataGridFilterField[]) => void;
  setAdvSearchInputs: (advSearch: IAdvSearch) => void;
}

export interface ContractsGridSessionState {
  timeFrameState: IContractTimeFrame;
  setTimeFrameState: (timeFrameState: IContractTimeFrame) => void;
  selectedSearchType: string;
  setSelectedSearchType: (searchType: string) => void;
}

interface RefreshNotificationCounterState {
  counter: number;
  increaseCounter: () => void;
}
const initialTimeframeState = {
  startDate: dayjs().add(-2, 'months').toDate(),
  endDate: null,
} as IContractTimeFrame;

const initalAdvSearchInputs = {
  upcCodeSearchType: null,
  upc: '',
  orderCode: '',
  brand: null,
  proposal: undefined,
  description: '',
  isSearchExecuted: false,
} as IAdvSearch;

export const useContractsGridLocalStore = create<ContractsGridLocalState>()(
  devtools(
    persist(
      (set) => ({
        advSearchInputs: initalAdvSearchInputs,
        dataGridFilters: [],
        setDataGridFilters: (value?: DataGridFilterField[]) => set(() => ({ dataGridFilters: value })),
        setAdvSearchInputs: (advSearch: IAdvSearch) => set(() => ({ advSearchInputs: advSearch })),
      }),
      {
        name: 'contracts-grid-local-storage',
      }
    )
  )
);

export const useContractsGridSessionStore = create<ContractsGridSessionState>()(
  devtools(
    persist(
      (set) => ({
        timeFrameState: initialTimeframeState,
        setTimeFrameState: (timeFrameState: IContractTimeFrame) => set(() => ({ timeFrameState: timeFrameState })),
        selectedSearchType: SearchGridTypes.AllContracts, //default
        setSelectedSearchType: (searchType: string) => set(() => ({ selectedSearchType: searchType })),
      }),
      {
        name: 'contracts-grid-session-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export const useRefreshNotificationCounterState = create<RefreshNotificationCounterState>()(
  devtools(
    persist(
      (set) => ({
        counter: 0,
        increaseCounter: () => set((state) => ({ counter: state.counter + 1 })),
      }),
      {
        name: 'refresh-notification-counter',
      }
    )
  )
);
