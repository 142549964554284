import { Store, StorePopover } from '@dierbergs-markets/react-component-library';
import { IStoreSelection } from '../../../../models';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import { getDistinctStores } from '../../../../utilities/ContractUtilities';

export default function ContractHubStorePopover(props: { stores: IStoreSelection; id: string }) {
  const { referenceData } = useApplicationContextState();

  function isAnyStoreSelected(storeSelections: IStoreSelection) {
    return storeSelections.storeGroupIds.length > 0 || storeSelections.storeIds.length > 0;
  }

  const hasStores = isAnyStoreSelected(props.stores);

  if (!hasStores) return <div>no stores</div>;

  const distinctStores: Store[] = getDistinctStores(props.stores, referenceData).map((x) => ({
    number: x.id,
    name: x.displayName,
  }));
  return <StorePopover id={props.id} stores={distinctStores} />;
}
