import { IContract, IPricing, IPricingAreaItem } from '../../../../../models';
import * as PG from '@dierbergs-markets/react-feature-library';
import { MdOutlineWarningAmber } from 'react-icons/md';
import { CorePricingGridColumnFields, CurrencyCellContent, PricingGrid } from '@dierbergs-markets/react-feature-library';
import { memo, useCallback, useMemo, useState } from 'react';
import { defaultColors } from '../../../../../styles/variables';
import { PricingGridPricingItem } from './utilities/PricingUtilities';
import { ValidationIssue } from '../../../../../classes/ValidationIssue';
import { toDataGridCellMessage } from '../../../../../utilities/DataGridUtilities';
import { pricingValidationService } from '../../../../../services/pricingValidationService';
import { useContractValidationStore } from '../../../../../store/contract/contractValidationStore';
import { ValidationScopes } from '../../../../../models/enums';
import { DataGridCellMessage } from '@dierbergs-markets/react-feature-library';
import { Circle, Inventory, Remove } from '@mui/icons-material';
import PricingSourceDialog from './PricingSourceDialog';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { getDistinctStores } from '../../../../../utilities/ContractUtilities';
import { usePricingGridInternalValidationHandler } from './hooks/usePricingGridInternalValidationHandler';
import useContractHubPricingGridSetup from './hooks/useContractHubPricingGridSetup';

interface IProps {
  contract: IContract;
  canModify: boolean;
  contractLoading: boolean;
  onPricingEdit: (price: IPricing) => void;
  onRowUpdate: (row: PricingGridPricingItem, messages?: DataGridCellMessage) => void;
  onMultiRowUpdate: (rows: PricingGridPricingItem[]) => void;
  onPricingUpdate: (pricingIndex: number, pricing: IPricing) => void;
  errors?: ValidationIssue[];
}

const PricingGridContainer = memo((props: IProps) => {
  // STATE
  const [sourceDialogSelectedRow, setSourceDialogSelectedRow] = useState<PricingGridPricingItem | null>(null);

  // MISC. HOOKS

  const { basePricingGridProps } = useContractHubPricingGridSetup({
    contract: props.contract,
    canModify: props.canModify,
    invokePricingEdit,
    updatePricing: props.onPricingUpdate,
  });

  const contractPricingItemValidationIssues = useContractValidationStore((s) =>
    s.validationIssues.filter((e) => e.scope === ValidationScopes.PricingItems)
  );

  const pricingAreaMisMatches = useContractValidationStore((s) =>
    s.validationIssues.filter(
      (e) =>
        e.scope === ValidationScopes.PricingConfigurations &&
        e.type === PG.PricingGridInternalValidationIssueType.RegularPriceStoreAreaPricingStoreAreaMismatch
    )
  );
  const { handleInternalPricingGridValidation } = usePricingGridInternalValidationHandler();
  const { validationIssues, setValidationIssuesByIdentifierScope } = useContractValidationStore();
  const { referenceData } = useApplicationContextState();

  // MEMOS
  const externalMessages = useMemo(() => {
    const getMessageUniqueId = (m: DataGridCellMessage) => `${m.rowId}_${m.field}_${m.severity}_${m.message}`;

    const combinedCellMessages: DataGridCellMessage[] = [];
    const uniqueCellMessagesSet = new Set<string>();

    //ensure any duplicate messages get filtered out before sending to PricingGrid
    contractPricingItemValidationIssues.map(toDataGridCellMessage).forEach((cellMessage) => {
      const key = getMessageUniqueId(cellMessage);
      if (!uniqueCellMessagesSet.has(key)) {
        uniqueCellMessagesSet.add(key);
        combinedCellMessages.push(cellMessage);
      }
    });

    return combinedCellMessages;
  }, [contractPricingItemValidationIssues, validationIssues]);

  // EFFECTS

  // FUNCTIONS

  function mapPricingGridPriceToPricingItem(sku: number, pricingGridPrice?: PG.IPrice): IPricingAreaItem {
    return { sku, multiple: pricingGridPrice?.multiple ?? 0, price: pricingGridPrice?.price ?? 0 };
  }
  const handleRowUpdate = useCallback(
    (row: PricingGridPricingItem) => {
      const rowValidationIssues: ValidationIssue[] = [];

      if (!referenceData) return;

      row.prices.forEach((price: (PG.IPrice | undefined)[], priceIndex) => {
        if (!price) return;
        price.forEach((priceAreaPrice, priceAreaIndex) => {
          const pricingAreaItem = mapPricingGridPriceToPricingItem(row.item.sku, priceAreaPrice);
          const validationResult = pricingValidationService.validatePricingItem(
            props.contract,
            pricingAreaItem,
            priceIndex,
            priceAreaIndex,
            referenceData
          );
          rowValidationIssues.push(...validationResult);
        });
      });

      setValidationIssuesByIdentifierScope(row.item.sku.toString(), ValidationScopes.PricingItems, rowValidationIssues);

      props.onRowUpdate(row);
    },
    [props.contract, props.onRowUpdate, setValidationIssuesByIdentifierScope]
  );

  function invokePricingEdit(pricing: IPricing) {
    props.canModify && props.onPricingEdit(pricing);
  }

  const handleInternalValidationComplete = useCallback(
    (cellMessages: DataGridCellMessage[], pricingConfigMessages: PG.PricingConfigMessage[]) => {
      handleInternalPricingGridValidation(cellMessages, pricingConfigMessages);
    },
    [handleInternalPricingGridValidation]
  );

  return (
    <div>
      {pricingAreaMisMatches.length > 0 && (
        <div style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <MdOutlineWarningAmber style={{ color: defaultColors.orange, marginRight: '5px', fontSize: '16px' }} />
          {pricingAreaMisMatches[0].message}
        </div>
      )}
      {basePricingGridProps && (
        <PricingGrid
          {...basePricingGridProps}
          id={'PricingGrid'}
          findReplaceEnabled={true}
          columnOrderable
          maxBodyHeightOffset={496}
          headerRowHeight={160}
          actionConfig={[{ position: 'bottom', actions: ['Columns', 'Sort'], alignment: 'left' }]}
          processRowUpdate={handleRowUpdate}
          processMultiRowUpdate={props.onMultiRowUpdate}
          onInternalValidationComplete={handleInternalValidationComplete}
          preferences={{
            storesFilter: getDistinctStores(props.contract.terms.stores, referenceData).map((store) => store.id),
            columns: {
              visibility: {
                suggestedRetail: true,
                source: true,
                [CorePricingGridColumnFields.ItemOrderCode]: true,
              },
              order: {
                source: 3.1,
                suggestedRetail: 10.1,
              },
            },
          }}
          additionalColumns={[
            {
              field: 'source',
              headerName: 'Source',
              type: 'string',
              width: 55,
              renderBodyCellContent: (params) => {
                const pricingItem = params.row as PricingGridPricingItem;

                const icon = pricingItem.source?.shipper ? <Inventory /> : pricingItem.source ? <Circle /> : <Remove />;

                return (
                  <div
                    style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center', width: '100%' }}
                    onClick={() => setSourceDialogSelectedRow(pricingItem)}
                  >
                    {icon}
                    {pricingItem.sources.length > 1 ? '..' : ''}
                  </div>
                );
              },
            },
            {
              field: 'suggestedRetail',
              headerName: 'Sugg Retail',
              type: 'string',
              width: 95,
              renderBodyCellContent: (params) => {
                const pricingItem = params.row as PricingGridPricingItem;

                if (!pricingItem.suggestedRetailPrice) return <></>;
                return <CurrencyCellContent value={pricingItem.suggestedRetailPrice} multiple={pricingItem.suggestedRetailMultiple} />;
              },
            },
          ]}
          costImplicationGroupedHeaderCellCss={{
            root: {
              backgroundColor: defaultColors.white,
              borderRadius: '16px 16px 0 0',
              outline: `1px solid ${defaultColors.grey}`,
              fontSize: '16px',
              paddingTop: '13px',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
            subRow: {
              marginTop: '10px',
            },
          }}
          messages={externalMessages}
        />
      )}
      <PricingSourceDialog selectedRow={sourceDialogSelectedRow} onClose={() => setSourceDialogSelectedRow(null)} />
    </div>
  );
});

export default PricingGridContainer;
