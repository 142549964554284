import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ValidationIssue } from '../../classes/ValidationIssue';
import { ValidationScopes, ValidationSeverity } from '../../models/enums';

export interface ContractValidationStore {
  showValidationIssues: boolean;
  validationIssues: ValidationIssue[];
  hasInternalPricingGridErrors: boolean;
  hasInternalPricingGridWarnings: boolean;
  hasInternalUpcDataGridErrors: boolean;
  hasInternalUpcDataGridWarnings: boolean;
  setHasInternalPricingGridErrors: (value: boolean) => void;
  setHasInternalPricingGridWarnings: (value: boolean) => void;
  setHasInternalUpcDataGridErrors: (value: boolean) => void;
  setHasInternalUpcDataGridWarnings: (value: boolean) => void;
  hasPricingWarnings: () => boolean;
  hasPricingErrors: () => boolean;
  hasUpcErrors: () => boolean;
  hasUpcWarnings: () => boolean;
  hasLumpSumWarnings: () => boolean;
  hasLumpSumErrors: () => boolean;
  setShowValidationIssues: (value: boolean) => void;
  setValidationIssues: (value: ValidationIssue[]) => void;
  setValidationIssuesByIdentifierScope: (identifier: string, validationScope: string, validationIssues: ValidationIssue[]) => void;
  clearAllValidationIssues: () => void;
  resetState: () => void;
}

const InitialState = {
  showValidationIssues: false,
  hasInternalPricingGridErrors: false,
  hasInternalPricingGridWarnings: false,
  hasInternalUpcDataGridErrors: false,
  hasInternalUpcDataGridWarnings: false,
  validationIssues: [],
};

export const useContractValidationStore = create<ContractValidationStore>()(
  devtools(
    (set, get) => ({
      ...InitialState,
      hasPricingErrors: () => {
        const state = get();
        const hasValidationIssues = state.validationIssues.some(
          (issue) => issue.scope === ValidationScopes.PricingItems && issue.severity === ValidationSeverity.Error
        );
        return state.hasInternalPricingGridErrors || hasValidationIssues;
      },
      hasPricingWarnings: () => {
        const state = get();
        const hasValidationIssues = state.validationIssues.some(
          (issue) => issue.scope === ValidationScopes.PricingItems && issue.severity === ValidationSeverity.Warning
        );
        return state.hasInternalPricingGridWarnings || hasValidationIssues;
      },
      hasUpcErrors: () => {
        const state = get();
        const hasValidationIssues = state.validationIssues.some((issue) =>
          [ValidationScopes.ContractItem, ValidationScopes.ContractItems, ValidationScopes.ContractTermsForItem].includes(issue.scope)
        );
        return state.hasInternalUpcDataGridErrors || (state.showValidationIssues && hasValidationIssues);
      },
      hasUpcWarnings: () => {
        const state = get();
        const hasValidationIssues = state.validationIssues.some(
          (issue) =>
            issue.severity === ValidationSeverity.Warning &&
            [ValidationScopes.ContractItem, ValidationScopes.ContractItems, ValidationScopes.ContractTermsForItem].includes(issue.scope)
        );
        return state.hasInternalUpcDataGridWarnings || (state.showValidationIssues && hasValidationIssues);
      },
      hasLumpSumErrors: () => {
        const state = get();
        const hasValidationIssues = state.validationIssues.some(
          (issue) => issue.severity === ValidationSeverity.Error && [ValidationScopes.ContractTermsForLumpSum].includes(issue.scope)
        );
        return state.showValidationIssues && hasValidationIssues;
      },
      hasLumpSumWarnings: () => {
        const state = get();
        const hasValidationIssues = state.validationIssues.some(
          (issue) => issue.severity === ValidationSeverity.Warning && [ValidationScopes.ContractTermsForLumpSum].includes(issue.scope)
        );
        return state.showValidationIssues && hasValidationIssues;
      },
      setValidationIssues: (value: ValidationIssue[]) => set(() => ({ validationIssues: value }), false, 'setValidationIssues'),
      setValidationIssuesByIdentifierScope: (identifier: string | number, validationScope: string, validationIssues: ValidationIssue[]) =>
        set(
          (state) => {
            const otherValidationIssues = state.validationIssues.filter(
              (issue) => !(issue.identifier === identifier && issue.scope === validationScope)
            );

            const revisedValidationIssues = [...otherValidationIssues, ...validationIssues];
            return {
              validationIssues: revisedValidationIssues,
            };
          },
          false,
          'setValidationIssuesByIdentifierScope'
        ),
      clearAllValidationIssues: () =>
        set(
          (state) => {
            return {
              validationIssues: [],
            };
          },
          false,
          'clearAllValidationIssues'
        ),
      setShowValidationIssues: (value: boolean) => set(() => ({ showValidationIssues: value }), false, 'setShowValidationIssues'),
      setHasInternalPricingGridErrors: (value: boolean) =>
        set(
          (state) => {
            // Only update if the value has changed
            if (state.hasInternalPricingGridErrors !== value) {
              return { hasInternalPricingGridErrors: value };
            }
            return state; // Return the state unchanged if the value is the same
          },
          false,
          'setHasInternalPricingGridErrors'
        ),

      setHasInternalPricingGridWarnings: (value: boolean) =>
        set(
          (state) => {
            // Only update if the value has changed
            if (state.hasInternalPricingGridWarnings !== value) {
              return { hasInternalPricingGridWarnings: value };
            }
            return state; // Return the state unchanged if the value is the same
          },
          false,
          'setHasInternalPricingGridWarnings'
        ),
      setHasInternalUpcDataGridErrors: (value: boolean) =>
        set(() => ({ hasInternalUpcDataGridErrors: value }), false, 'setHasInternalUpcDataGridErrors'),
      setHasInternalUpcDataGridWarnings: (value: boolean) =>
        set(() => ({ hasInternalUpcDataGridWarnings: value }), false, 'setHasInternalUpcDataGridWarnings'),
      resetState: () => set({ ...InitialState }, false, 'resetState'),
    }),
    { name: 'contract-validation-state' }
  )
);
