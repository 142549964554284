import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import { accountService, IExternalAccountSearchRequest } from '../../../../services/account/accountService';
import { IExternalAccountDetails, IExternalAccountName } from '../../../../models';
import { HttpErrorResponse } from '../../../../services/contractHubApi';
import {
  Button,
  DataGrid,
  DataGridColDef,
  DataGridFilterDateRange,
  DataGridFilterField,
  DataGridRowModel,
  DataGridSortField,
  IPagedResult,
  IconButton,
} from '@dierbergs-markets/react-component-library';
import { useSnackbar } from 'notistack';
import { Permission, UserType } from '../../../../models/enums';
import { RouteEnum } from '../../../layout/PageRouter';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SharedAdminStyles } from '../styles/SharedAdminStyles';
import EditExternalAccountModal from './components/EditExternalAccountModal';
import PageHeader from '../../../layout/components/PageHeader';
import PageBody from '../../../layout/components/PageBody';

export default function AdminExternalAccountsGridPage(): JSX.Element {
  const defaultPageSize = 50;

  const navigate = useNavigate();
  const { user } = useApplicationContextState();
  const [accounts, setAccounts] = useState<IExternalAccountDetails[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [addAccountLoading, setAddAccountLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<IExternalAccountSearchRequest>({
    page: { number: 0, size: defaultPageSize },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [showEditAccountModal, setShowEditAccountModal] = useState<boolean>(false);
  const [externalAccountToEdit, setExternalAccountToEdit] = useState<IExternalAccountDetails | null>(null);

  const [canAddAccount, setCanAddAccount] = useState<boolean>(false);

  const [responseData, setResponseData] = useState<IPagedResult<IExternalAccountDetails> | undefined>(undefined);

  const { styles } = SharedAdminStyles;

  const defaultInitialExternalAccount: IExternalAccountDetails = {
    externalAccountId: 0,
    name: '',
    customerIds: [],
  };

  const loadAccountResponseData = async () => {
    setLoading(true);
    const response = await accountService.searchAccounts({
      query: searchQuery.query,
      page: {
        number: searchQuery.page.number,
        size: defaultPageSize,
      },
      externalAccountId: searchQuery.externalAccountId,
      customerIdFilter: searchQuery.customerIdFilter,
      sort: searchQuery.sort,
    });
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to retrieve accounts.', { variant: 'error' });
      setLoading(false);
    } else {
      setResponseData(response);
    }
  };

  useEffect(() => {
    if (responseData) {
      if (searchQuery.page.number === 0) {
        setAccounts(responseData.results);
      } else {
        setAccounts([...accounts, ...responseData.results]);
      }

      setTotalCount(responseData.totalResults);
      setLoading(false);
    }
  }, [responseData]);

  useEffect(() => {
    if (!user) return;

    // if external user navigate to /accountId
    if (user.userType === UserType.External) {
      navigate(`${RouteEnum.AdminExternalAccounts}/${user.externalAccountId}`);
    } else if (user.permissions.includes(Permission.ExternalAccountsManage)) {
      loadAccountResponseData();
    } else {
      navigate(RouteEnum.Dashboard);
    }

    setCanAddAccount(user.permissions.includes(Permission.ExternalAccountsManage));
  }, [user, searchQuery]);

  const getGridColumns = (): DataGridColDef[] => {
    return [
      {
        field: 'externalAccountId',
        headerName: 'External Account ID',
        type: 'string',
        width: 150,
        filterable: true,
        sortable: true,
      },
      {
        field: 'name',
        headerName: 'Account Name',
        type: 'string',
        width: 400,
        filterable: true,
        sortable: true,
      },
      {
        field: 'customerIds',
        headerName: 'Customers',
        minWidth: 150,
        flex: 1,
        type: 'string',
        filterable: true,
        sortable: true,
        renderBodyCellContent: (x) => {
          if (!x.value) return <></>;
          return x.value.join(',');
        },
      },
    ];
  };

  const navigateToAccount = (externalAccountId: number) => {
    navigate(`${RouteEnum.AdminExternalAccounts}/${externalAccountId}`);
  };

  const handleFilterChange = (
    filters: DataGridFilterField[],
    getFilterValue: <T>(filters: DataGridFilterField[], field: string) => T | undefined
  ) => {
    const name = getFilterValue<string>(filters, 'name');
    const externalAccountId = getFilterValue<string>(filters, 'externalAccountId');
    const customerId = getFilterValue<string>(filters, 'customerIds');
    const pageNumber = 0; // reset page to 0 on any filter change
    setSearchQuery({
      ...searchQuery,
      query: name,
      externalAccountId: externalAccountId ? +externalAccountId : undefined,
      customerIdFilter: customerId,
      page: {
        number: pageNumber,
        size: defaultPageSize,
      },
    });
  };

  const handleAddAccount = () => {
    setExternalAccountToEdit(defaultInitialExternalAccount);
    setShowEditAccountModal(true);
  };

  const handleBackToAdmin = () => {
    // if we had a regular admin page, this should go back to admin, but for now, redirect back to dashboard
    navigate(RouteEnum.Dashboard);
  };

  const handleRowSelected = (row: DataGridRowModel) => {
    navigateToAccount(row.externalAccountId);
  };

  const handleAddAccountClose = () => {
    setExternalAccountToEdit(null);
    setShowEditAccountModal(false);
  };

  const handleAddAccountAccept = async (account: IExternalAccountName) => {
    setAddAccountLoading(true);
    const response = await accountService.createAccount(account.name);
    setAddAccountLoading(false);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to create account.', { variant: 'error' });
    } else {
      navigateToAccount(response.externalAccountId);
    }
  };

  const handleOnRowsScrollEnd = () => {
    if (accounts.length < totalCount) {
      setSearchQuery({
        ...searchQuery,
        page: {
          size: defaultPageSize,
          number: searchQuery.page.number + 1,
        },
      });
    }
  };

  function handleSortingColumnChange(fields: DataGridSortField[]) {
    setSearchQuery({
      ...searchQuery,
      page: { ...searchQuery.page, number: 0 },
      sort: fields,
    });
  }

  return (
    <>
      <PageHeader>
        <IconButton id={'BackToDashboard'} icon={<ArrowBackIcon />} onClick={handleBackToAdmin} />
      </PageHeader>
      <PageBody>
        <Box sx={styles.pageBody}>
          <Box sx={[styles.toolBar]}>
            {canAddAccount && (
              <Box sx={styles.addAccountBtn}>
                <Button id={'NewAccount'} onClick={handleAddAccount} variant={'rounded-sides'} color={'green'}>
                  Add New Account
                </Button>
              </Box>
            )}
          </Box>
          <DataGrid
            id={'external-accounts-data-grid'}
            rows={accounts}
            getRowId={(a: IExternalAccountDetails) => a.externalAccountId}
            columns={getGridColumns()}
            bodyRowHeight={52}
            headerRowHeight={56}
            isLoading={loading}
            showInputValidationErrors={true}
            onFilterChange={handleFilterChange}
            onRowClick={handleRowSelected}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            onColumnSortChange={handleSortingColumnChange}
            sortingMode={'server'}
          />
          {showEditAccountModal && externalAccountToEdit && (
            <EditExternalAccountModal
              onAccept={handleAddAccountAccept}
              onCancel={handleAddAccountClose}
              initialAccount={externalAccountToEdit}
              saving={addAccountLoading}
            />
          )}
        </Box>
      </PageBody>
    </>
  );
}
