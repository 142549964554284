import { ValidationIssue } from '../classes/ValidationIssue';
import { ValidationScopes, ValidationSeverity, ValidationSource } from '../models/enums';
import { IContract, IPricingAreaItem } from '../models';
import { CorePricingGridColumnFields, DataGridCellMessage } from '@dierbergs-markets/react-feature-library';
import { IReferenceDataState } from '../contexts/ApplicationContext';

/**
 * Converts DataGridCellMessage to ValidationIssue for pricing validation
 */
function convertPricingGridMessageToValidationIssue(message: DataGridCellMessage): ValidationIssue {
  return new ValidationIssue({
    scope: ValidationScopes.PricingItems,
    field: message.field,
    message: message.message,
    identifier: message.rowId,
    source: ValidationSource.Ui,
    severity: message.isError() ? ValidationSeverity.Error : ValidationSeverity.Warning,
  });
}

/**
 * Validates pricing data applying both grid-level and contract-level validation rules
 */
function validateContractPricing(contract: IContract, referenceData: IReferenceDataState): ValidationIssue[] {
  const errors: ValidationIssue[] = [];

  if (!contract.pricings) return errors;

  contract.pricings.forEach((pricing, pricingIndex) => {
    pricing.areas.forEach((area, pricingAreaIndex) => {
      area.items.forEach((pai) => {
        errors.push(...validatePricingItem(contract, pai, pricingIndex, pricingAreaIndex, referenceData));
      });
    });
  });

  return errors;
}

function validatePricingItem(
  contract: IContract,
  pricingAreaItem: IPricingAreaItem,
  pricingIndex: number,
  pricingAreaIndex: number,
  referenceData: IReferenceDataState
): ValidationIssue[] {
  const errors: ValidationIssue[] = [];

  const item =
    contract.terms.contractItems.find((q) => q.sku === pricingAreaItem.sku)?.item ??
    contract.terms.contractItems.flatMap((ci) => ci.childData).find((q) => q?.item?.sku === pricingAreaItem.sku)?.item;
  if (!item) return [];

  // Validate pricing values
  if (pricingAreaItem.price && item?.regularPrices && contract.pricings) {
    const pricingArea = contract.pricings[pricingIndex]?.areas[pricingAreaIndex];
    if (!pricingArea) return [];

    const applicableRegularPrices = item.regularPrices.filter((rp) => rp.stores.some((storeId) => pricingArea.stores.includes(storeId)));

    const isLowerThanAtLeastOne = applicableRegularPrices.some(
      (rp) => pricingAreaItem.price && pricingAreaItem.price / pricingAreaItem.multiple <= rp.price
    );

    if (!isLowerThanAtLeastOne) {
      errors.push(
        new ValidationIssue({
          scope: ValidationScopes.PricingItems,
          field: CorePricingGridColumnFields.Price_ByUniqueId(pricingIndex, pricingAreaIndex),
          message: `${item?.upc ?? pricingAreaItem.sku}: Price must be lower than or equal to at least one store's Regular Price on the contract.`,
          identifier: pricingAreaItem.sku.toString(),
          source: ValidationSource.Ui,
        })
      );
    }
  }
  return errors;
}

/**
 * Helper to determine if a validation message already exists
 */
function findMatchingValidationIssue(issues: ValidationIssue[], scope: string, field: string, identifier: string): ValidationIssue | undefined {
  return issues.find((issue) => issue.scope === scope && issue.field === field && issue.identifier === identifier);
}

export const pricingValidationService = {
  findMatchingValidationIssue,
  convertPricingGridMessageToValidationIssue,
  validateContractPricing,
  validatePricingItem,
};
