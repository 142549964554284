import { useEffect, useState, Fragment, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IAdPlannerCostBreakdown, IContractAdPlannerExport } from '../../../models';
import PageHeader from '../../layout/components/PageHeader';
import { DbgRoundedButton } from '../../components/buttons/DbgRoundedButton';
import PageBody from '../../layout/components/PageBody';
import { DbgButtonSize } from '../../../models/enums';
import { RouteEnum } from '../../layout/PageRouter';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { contractAdPlannerExportService } from '../../../services/contract/contractAdPlannerExportService';
import { Button, Checkbox, ConfirmationDialog, Select, Spinner, TextInput } from '@dierbergs-markets/react-component-library';
import { enqueueSnackbar } from 'notistack';
import { allowedKeys } from './components/dialogs/ContractTasksCommon';
import { addDays, format, startOfDay } from 'date-fns';
import ContractHubStorePopover from './components/ContractHubStorePopover';
import { HttpErrorResponse } from '../../../services/contractHubApi';
import { useAdPlannerExportStyles } from './styles/AdPlannerExportPageStyles';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

interface IAdStartDateOption {
  text: string;
  value: Date;
}

interface ExportedAdGroup {
  uniqueId: string;
  adGroupName: string;
  comments: string;
  adStartDate: Date;
}

interface ExportedModule {
  uniqueId: string;
  adStartDate: Date;
}

interface ExportedValues {
  adGroups: ExportedAdGroup[];
  modules: ExportedModule[];
}

export default function AdPlannerExportPage() {
  //State
  const [contractExport, setContractExport] = useState<IContractAdPlannerExport | undefined>(undefined);
  const [selectedAdGroups, setSelectedAdGroups] = useState<string[]>([]);
  const [selectedModules, setSelectedModules] = useState<string[]>([]);
  const [adStartDateOptions, setAdStartDateOptions] = useState<IAdStartDateOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [exporting, setExporting] = useState(false);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);
  const [exportedAdGroups, setExportedAdGroups] = useState<string[]>([]);
  const [exportedModules, setExportedModules] = useState<string[]>([]);

  const exportedValuesRef = useRef<ExportedValues>({
    adGroups: [],
    modules: [],
  });

  //Variables
  const missingAdLocationMessage = 'No Ad Location selected';

  //Hooks
  const navigate = useNavigate();
  const { referenceData } = useApplicationContextState();
  const { id } = useParams();
  const { css, classes, cx } = useAdPlannerExportStyles();

  // Schema validation
  const schema = Joi.object({
    adGroups: Joi.array().items(
      Joi.object({
        adGroupName: Joi.string()
          .required()
          .max(50)
          .label('Ad Group Name')
          .messages({ 'string.empty': 'Ad Group Name is required.', 'string.max': 'Ad Group Name cannot be greater than 50 characters.' }),
        comments: Joi.string()
          .allow(null, '')
          .max(100)
          .label('Comment')
          .messages({ 'string.max': 'Comments cannot be greater than 100 characters.' }),
        adStartDate: Joi.date().required().label('Ad Start Date'),
      })
    ),
  });

  const resolver = joiResolver(schema, { abortEarly: false, allowUnknown: true }, { mode: 'async' });

  const { control, formState, getValues } = useForm<IContractAdPlannerExport>({
    defaultValues: { adGroups: [] },
    resolver,
    mode: 'all',
    shouldFocusError: false,
  });
  const { replace } = useFieldArray({
    control,
    name: 'adGroups',
  });

  //Effects
  useEffect(() => {
    if (id) loadContractExport(+id);
  }, [id]);

  useEffect(() => {
    const weeksBack = 84; // 12 * 7 Days
    const weeksOut = 112; // 16 * 7 Days
    const startDate = addDays(getAdStartDate(), -weeksBack);

    const optionList: IAdStartDateOption[] = [];
    for (let i = 0; i <= weeksBack + weeksOut - 1; i += 7) {
      const optionDate = addDays(startDate, i);
      optionList.push({ text: format(optionDate, 'M/d/yyyy'), value: optionDate });
    }

    setAdStartDateOptions(optionList);
  }, []);

  //Async Functions
  const handleExport = async () => {
    if (!id || !contractExport) return;

    setExporting(true);

    // Get adGroups from JOI since it holds the updated values
    const { adGroups } = getValues();

    const adGroupsToExport = adGroups.filter((q) => !!q.adSiteId && selectedAdGroups.findIndex((i) => i === q.uniqueId) > -1);
    const modulesToExport = contractExport.modules.filter((q) => selectedModules.findIndex((i) => i === q.uniqueId) > -1);

    const exportObj = {
      contractId: contractExport.contractId,
      contractNumber: contractExport.contractNumber,
      adGroups: adGroupsToExport,
      modules: modulesToExport,
    };

    const response = await contractAdPlannerExportService.submitAdPlannerExport(+id, exportObj);

    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Error exporting contract to Ad Planner.', { variant: 'error' });
    } else {
      const updatedExportedAdGroups = [...exportedValuesRef.current.adGroups];

      adGroupsToExport.forEach((group) => {
        const existingIndex = updatedExportedAdGroups.findIndex((g) => g.uniqueId === group.uniqueId);

        const valueToStore = {
          uniqueId: group.uniqueId,
          adGroupName: group.adGroupName,
          comments: group.comments || '',
          adStartDate: group.adStartDate,
        };

        if (existingIndex >= 0) {
          updatedExportedAdGroups[existingIndex] = valueToStore;
        } else {
          updatedExportedAdGroups.push(valueToStore);
        }
      });

      const updatedExportedModules = [...exportedValuesRef.current.modules];

      modulesToExport.forEach((module) => {
        const existingIndex = updatedExportedModules.findIndex((m) => m.uniqueId === module.uniqueId);

        const valueToStore = {
          uniqueId: module.uniqueId,
          adStartDate: module.adStartDate,
        };

        if (existingIndex >= 0) {
          updatedExportedModules[existingIndex] = valueToStore;
        } else {
          updatedExportedModules.push(valueToStore);
        }
      });

      exportedValuesRef.current = {
        adGroups: updatedExportedAdGroups,
        modules: updatedExportedModules,
      };

      setExportedAdGroups([...exportedAdGroups, ...adGroupsToExport.map((q) => q.uniqueId)]);
      setExportedModules([...exportedModules, ...modulesToExport.map((q) => q.moduleNumber)]);

      enqueueSnackbar('Successfully exported contract.', { variant: 'success' });
    }

    setExporting(false);

    setTimeout(() => {
      updateExportButtonState();
    }, 0);
  };

  const loadContractExport = async (contractId: number) => {
    if (!referenceData) return;

    setLoading(true);
    setExportedAdGroups([]);
    setExportedModules([]);
    const response = await contractAdPlannerExportService.getAdPlannerExport(contractId);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving Ad Planner Export.', { variant: 'error' });
    } else {
      const adPriceType = referenceData.priceTypes.all.find((q) => q.name === 'Ad');
      setContractExport(response);
      setSelectedAdGroups(response.adGroups.filter((q) => q.pricingTypeId === adPriceType?.id && !!q.adSiteId).map((q) => q.uniqueId));
      replace(response.adGroups);
      setSelectedModules(response.modules.map((q) => q.uniqueId));
    }
    setLoading(false);
  };

  //Functions

  const getAdStartDate = () => {
    // converted from the VB script in AdPlannerInput.asp
    // try to keep the original logic intact.
    const curDate = startOfDay(new Date());
    const curDay = curDate.getDay(); // getDay() returns 0 for Sunday, 1 for Monday, etc.

    switch (curDay) {
      // Sun
      case 0:
        return addDays(curDate, -5); //javascript is 0 based, so the offset needs an extra -1
      // Mon, Tue, Wed, Thu, Fri, Sat
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return addDays(curDate, 2 - curDay);
      default:
        return curDate;
    }
  };

  const getUtcDate = (input: Date) => {
    return new Date(input.getTime() + input.getTimezoneOffset() * 60000);
  };

  const hasAdGroupBeenModified = (adGroupId: string) => {
    if (!contractExport) return false;

    const { adGroups } = getValues();

    const exportedAdGroup = exportedValuesRef.current.adGroups.find((group) => group.uniqueId === adGroupId);

    if (!exportedAdGroup) return false;

    const adGroupIndex = contractExport.adGroups.findIndex((ag) => ag.uniqueId === adGroupId);
    const currentAdGroup = adGroups[adGroupIndex];

    return (
      currentAdGroup?.adGroupName !== exportedAdGroup.adGroupName ||
      currentAdGroup?.comments !== exportedAdGroup.comments ||
      new Date(currentAdGroup.adStartDate).toISOString() !== new Date(exportedAdGroup.adStartDate).toISOString()
    );
  };

  const hasModuleBeenModified = (moduleId: string) => {
    if (!contractExport) return false;

    const exportedModule = exportedValuesRef.current.modules.find((module) => module.uniqueId === moduleId);

    if (!exportedModule) return false;

    const currentModule = contractExport.modules.find((module) => module.uniqueId === moduleId);
    if (!currentModule) return false;

    return new Date(currentModule.adStartDate).toISOString() !== new Date(exportedModule.adStartDate).toISOString();
  };

  const updateExportButtonState = () => {
    if (!contractExport) return;

    // Get ids of exported groups and modules
    const exportedAdGroupIds = exportedValuesRef.current.adGroups.map((group) => group.uniqueId);
    const exportedModuleIds = exportedValuesRef.current.modules.map((module) => module.uniqueId);

    // Check if any exported ad groups have been modified
    const anyExportedGroupsModified = selectedAdGroups.some((id) => exportedAdGroupIds.includes(id) && hasAdGroupBeenModified(id));

    // Check if any exported modules have been modified
    const anyExportedModulesModified = selectedModules.some((id) => exportedModuleIds.includes(id) && hasModuleBeenModified(id));

    // Check if all selected groups have been exported
    const allSelectedGroupsAreExported = selectedAdGroups.length > 0 && selectedAdGroups.every((id) => exportedAdGroupIds.includes(id));

    // Check if all selected modules have been exported
    const allSelectedModulesAreExported = selectedModules.length > 0 && selectedModules.every((id) => exportedModuleIds.includes(id));

    // Check validation errors
    const adGroupsWithErrors = contractExport.adGroups.filter((q, i) => (formState.errors.adGroups ? formState.errors.adGroups[i] : null));
    const hasSelectedGroupsWithErrors = selectedAdGroups.some((id) => adGroupsWithErrors?.find((e) => e.uniqueId === id));

    // Handle cases with no groups or modules
    const hasNoContentToExport =
      (contractExport.adGroups.length === 0 || !selectedAdGroups.length) && (contractExport.modules.length === 0 || !selectedModules.length);

    // Button should be disabled if nothing is selected
    if (hasNoContentToExport) {
      setExportDisabled(true);
      return;
    }

    // If we have AdGroups and some are selected
    const groupsNeedExporting = selectedAdGroups.length > 0 && (!allSelectedGroupsAreExported || anyExportedGroupsModified);

    // If we have Modules and some are selected
    const modulesNeedExporting = selectedModules.length > 0 && (!allSelectedModulesAreExported || anyExportedModulesModified);

    // Enable the button if anything needs exporting
    const shouldBeEnabled = groupsNeedExporting || modulesNeedExporting;

    // Other conditions that should disable the button
    const shouldBeDisabled =
      exporting || !shouldBeEnabled || hasSelectedGroupsWithErrors || contractExport.adGroups.every((ag) => ag.adSiteId === undefined);

    setExportDisabled(shouldBeDisabled);
  };

  const handleReturnToAllContracts = () => {
    if (
      exportedAdGroups.length === 0 &&
      exportedModules.length === 0 &&
      contractExport &&
      (contractExport.adGroups.length > 0 || contractExport.modules.length > 0)
    ) {
      setShowUnsavedChanges(true);
    } else {
      navigate(RouteEnum.Dashboard);
    }
  };

  const renderContractLink = (contractId: number, contractNumber: string) => {
    return (
      <a
        className={classes.contractLink}
        onClick={(e) => {
          e.preventDefault();
          navigate(`${RouteEnum.Contract}/${contractId}`, { state: { tab: 'Pricing' } });
        }}
      >
        {contractId === contractExport?.contractId ? 'Current Contract' : contractNumber}
      </a>
    );
  };

  const renderContractLinkOrTotal = (contractId: number, contractNumber: string, breakdowns: IAdPlannerCostBreakdown[]) => {
    if (breakdowns.length > 1) return 'Total';
    return breakdowns.length > 1 ? 'Totals' : renderContractLink(contractId, contractNumber);
  };

  const setAdGroupSelected = (adGroupUniqueId: string, selected: boolean) => {
    if (selected) setSelectedAdGroups([...selectedAdGroups, adGroupUniqueId]);
    else setSelectedAdGroups(selectedAdGroups.filter((q) => q != adGroupUniqueId));
  };

  const setModuleSelected = (moduleUniqueId: string, selected: boolean) => {
    if (selected) setSelectedModules([...selectedModules, moduleUniqueId]);
    else setSelectedModules(selectedModules.filter((q) => q != moduleUniqueId));
  };

  const handleModuleAdStartDateChange = (moduleUniqueId: string, adStartDate: string) => {
    if (!contractExport) return;

    setContractExport((prevContractExport) => {
      if (!prevContractExport) return prevContractExport;

      const updatedContractExport = {
        ...prevContractExport,
        modules: prevContractExport.modules.map((q) => {
          if (q.uniqueId === moduleUniqueId) {
            return {
              ...q,
              adStartDate: new Date(adStartDate),
            };
          } else {
            return q;
          }
        }),
      };

      const isModuleExported = exportedValuesRef.current.modules.some((module) => module.uniqueId === moduleUniqueId);

      if (isModuleExported) {
        const exportedModule = exportedValuesRef.current.modules.find((module) => module.uniqueId === moduleUniqueId);
        if (exportedModule) {
          const newDate = new Date(adStartDate);
          const exportedDate = new Date(exportedModule.adStartDate);

          if (newDate.toISOString() !== exportedDate.toISOString()) {
            setTimeout(() => setExportDisabled(false), 0);
          }
        }
      }

      return updatedContractExport;
    });
  };

  if (!referenceData) return <></>;

  return (
    <>
      <PageHeader>
        <div className={classes.contractPageHeader}>
          <DbgRoundedButton
            id="BackToDashboard"
            onClick={handleReturnToAllContracts}
            className={classes.headerBtn}
            dbgButtonSize={DbgButtonSize.Large}
          >
            <ArrowBackIcon className={classes.headerBtnIcon} />
          </DbgRoundedButton>
        </div>
      </PageHeader>
      <PageBody>
        <div className={classes.root}>
          {loading && (
            <div className={classes.header}>
              <div className={classes.titleContainer}>
                <div className={classes.title}>Loading...</div>
              </div>
            </div>
          )}
          {!loading && contractExport && (
            <>
              <div className={classes.header}>
                <div className={classes.titleContainer}>
                  <div className={classes.title}>Ad Planner Export</div>
                  <div className={classes.subTitle}>{`${contractExport.contractNumber} (${contractExport.contractId})`}</div>
                </div>
              </div>
              <div className={classes.mainContent}>
                {contractExport.adGroups.length === 0 && contractExport.modules.length === 0 && (
                  <div className={cx(classes.subTitle, classes.sectionSpace)}>No Ad Groups or Modules to Export</div>
                )}
                {contractExport.adGroups.length > 0 && (
                  <>
                    <div className={cx(classes.subTitle, classes.sectionSpace)}>Groups</div>
                    {contractExport?.adGroups.map((adGroup, idx) => {
                      return (
                        <div className={classes.subSectionSpaceWrapper} key={idx}>
                          <div
                            className={classes.subSectionSpace}
                            id={`adGroup-${idx}`}
                            key={`group-${idx}`}
                            title={!adGroup.adSiteId ? `Unable to export: ${missingAdLocationMessage}` : ''}
                          >
                            <div className={classes.checkboxWrapperAdGroup}>
                              <Checkbox
                                id={`AdGroupSelected-${idx}`}
                                disabled={!adGroup.adSiteId}
                                checked={!!adGroup.adSiteId && selectedAdGroups.findIndex((q) => q === adGroup.uniqueId) > -1}
                                onChange={(checked: boolean) => {
                                  setAdGroupSelected(adGroup.uniqueId, checked);
                                }}
                              />
                            </div>
                            <div className={classes.propertyDetailsContainer}>
                              <div className={classes.propertyDetails}>
                                <div className={classes.groupNameAndCommentContainer}>
                                  <div className={classes.groupNameContainer}>
                                    <Controller
                                      control={control}
                                      name={`adGroups.${idx}.adGroupName`}
                                      render={({ field }) => (
                                        <TextInput
                                          {...field}
                                          id={`groups.${idx}.name`}
                                          label="Group Name"
                                          disabled={!adGroup.adSiteId}
                                          classes={{
                                            errorText: classes.groupNameErrorText,
                                          }}
                                          allowedkeys={allowedKeys}
                                          tabIndex={idx + 1}
                                          onChange={(value: any) => {
                                            field.onChange(value);
                                            updateExportButtonState();
                                          }}
                                          value={field.value}
                                          errorMessage={
                                            (formState.errors.adGroups ? formState.errors.adGroups[idx] : (null as any))?.adGroupName?.message
                                          }
                                          placeholder="Group Name"
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className={classes.groupAdSiteIdContainer}>
                                    <div className={classes.groupAdSiteIdLabel}>Ad Location</div>
                                    {adGroup.adSiteId ? (
                                      <div className={classes.groupAdSiteIdName}>{referenceData.adSites.byId[adGroup.adSiteId].name}</div>
                                    ) : (
                                      <div className={classes.groupAdSiteIdMissing}>
                                        {missingAdLocationMessage}
                                        <Button
                                          id={`contractLnkBtn_${adGroup.uniqueId}`}
                                          className={classes.groupAdSiteIdContractLink}
                                          variant="link"
                                          onClick={() => navigate(`${RouteEnum.Contract}/${id}`, { state: { tab: 'Pricing' } })}
                                        >
                                          View Contract
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  <div className={classes.groupAdSiteStartDateContainer}>
                                    <Controller
                                      control={control}
                                      name={`adGroups.${idx}.adStartDate`}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          id={`groups.${idx}.adStartDate`}
                                          label="Ad Start Date"
                                          disabled={!adGroup.adSiteId}
                                          items={adStartDateOptions.map((i) => {
                                            return { text: i.text, value: i.value };
                                          })}
                                          onChange={(value: any) => {
                                            field.onChange(new Date(value));
                                            updateExportButtonState();
                                          }}
                                          value={field.value}
                                          classes={{
                                            inputContent: css(classes.groupSelect),
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className={classes.groupNameAndCommentContainer}>
                                  <div className={classes.groupCommentContainer}>
                                    <Controller
                                      control={control}
                                      name={`adGroups.${idx}.comments`}
                                      render={({ field }) => (
                                        <TextInput
                                          {...field}
                                          id={`groups.${idx}.comments`}
                                          label="Comment"
                                          disabled={!adGroup.adSiteId}
                                          classes={{
                                            errorText: classes.groupNameErrorText,
                                          }}
                                          allowedkeys={allowedKeys}
                                          tabIndex={idx + 1}
                                          onChange={(value: any) => {
                                            field.onChange(value);
                                            updateExportButtonState();
                                          }}
                                          value={field.value}
                                          errorMessage={
                                            (formState.errors.adGroups ? formState.errors.adGroups[idx] : (null as any))?.comments?.message
                                          }
                                          placeholder="Comments"
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={classes.adGroups}>
                                {adGroup.subGroups.map((subgroup, subGroupIdx) => {
                                  return (
                                    <table key={`AdGroup-${idx}-SubGroup-${subGroupIdx}`}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <table className={classes.gridTable}>
                                              <thead>
                                                <tr>
                                                  <th style={{ width: '200px' }}>Contract</th>
                                                  <th>SRP</th>
                                                  <th>Case List Cost</th>
                                                  <th>OI Allow</th>
                                                  <th>Billback</th>
                                                  <th>CMAP</th>
                                                  <th>Scandown</th>
                                                  <th>Stores</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    {renderContractLinkOrTotal(
                                                      contractExport.contractId,
                                                      contractExport.contractNumber,
                                                      subgroup.breakdowns
                                                    )}
                                                  </td>
                                                  <td>
                                                    {subgroup.multiple}/{subgroup.srp}
                                                  </td>
                                                  <td>{subgroup.caseListCost.toFixed(2)}</td>
                                                  <td>{subgroup.oiAllow.toFixed(2)}</td>
                                                  <td>{subgroup.billback.toFixed(2)}</td>
                                                  <td>{subgroup.cmap.toFixed(2)}</td>
                                                  <td>{subgroup.scandown.toFixed(2)}</td>
                                                  <td>
                                                    <div className={classes.groupStoresContainer}>
                                                      <ContractHubStorePopover
                                                        id={'contractStores'}
                                                        stores={{ storeGroupIds: [], storeIds: subgroup.stores }}
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>
                                                {subgroup.breakdowns.length > 1 &&
                                                  subgroup.breakdowns.map((breakdown, bdIdx) => (
                                                    <tr key={`breakdown-${breakdown.contractId}-${bdIdx}`}>
                                                      <td>{renderContractLink(breakdown.contractId, breakdown.contractNumber)}</td>
                                                      <td>{/* Multiple/SRP */}</td>
                                                      <td>{/* Case List Cost */}</td>
                                                      <td>{breakdown.oiAllow.toFixed(2)}</td>
                                                      <td>{breakdown.billback.toFixed(2)}</td>
                                                      <td>{breakdown.cmap.toFixed(2)}</td>
                                                      <td>{breakdown.scandown.toFixed(2)}</td>
                                                      <td>{/* Stores */}</td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            {subgroup.items.map((item, index) => (
                                              <div key={`upc-${item.upc}-${index}`} className={classes.propertyDetails}>
                                                <label>{item.upc}</label>
                                                <span>{item.description}</span>
                                              </div>
                                            ))}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
                {contractExport.modules.length > 0 && (
                  <>
                    <div className={cx(classes.subTitle, classes.sectionSpace)}>Modules</div>
                    {contractExport?.modules.map((module, mIdx) => {
                      return (
                        <div className={classes.subSectionSpaceWrapper} key={`module-${mIdx}`}>
                          <div className={classes.subSectionSpace}>
                            <div className={classes.checkboxWrapperModule}>
                              <Checkbox
                                id={`ModuleSelected-${mIdx}`}
                                checked={selectedModules.findIndex((q) => q === module.uniqueId) > -1}
                                onChange={(checked: boolean) => {
                                  setModuleSelected(module.uniqueId, checked);
                                }}
                              />
                            </div>
                            <div>
                              <div className={classes.propertyDetails}>
                                <div className={classes.smallTitle}>{module.moduleNumber}</div>
                              </div>
                              <div>
                                <div className={classes.propertyDetails}>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <table className={classes.gridTable}>
                                                    <thead>
                                                      <tr>
                                                        <th>Case List Cost</th>
                                                        <th>OI Allow</th>
                                                        <th>Billback</th>
                                                        <th>CMAP</th>
                                                        <th>Scandown</th>
                                                        <th className={classes.adStartDateColumn}>Ad Start Date</th>
                                                        <th>Stores</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>{module.caseListCost?.toFixed(2)}</td>
                                                        <td>{module.oiAllow?.toFixed(2)}</td>
                                                        <td>{module.billback?.toFixed(2)}</td>
                                                        <td>{module.cmap?.toFixed(2)}</td>
                                                        <td>{module.scandown?.toFixed(2)}</td>
                                                        <td>
                                                          <Select
                                                            id={`modules.${mIdx}.adStartDate`}
                                                            label="Ad Start Date"
                                                            items={adStartDateOptions.map((i) => {
                                                              return { text: i.text, value: i.value };
                                                            })}
                                                            onChange={(value) => handleModuleAdStartDateChange(module.uniqueId, value)}
                                                            value={module.adStartDate}
                                                            classes={{
                                                              inputContent: css(classes.groupSelect),
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          <div className={classes.groupStoresContainer}>
                                                            <ContractHubStorePopover
                                                              id={'contractStores'}
                                                              stores={{ storeGroupIds: [], storeIds: module.stores }}
                                                            />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <table className={classes.gridTable}>
                                            <thead>
                                              <tr>
                                                <th>Start</th>
                                                <th>Vendor</th>
                                                <th>Department</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>{format(getUtcDate(module.adStartDate), 'M/d/yyyy')}</td>
                                                <td>{module.supplierName}</td>
                                                <td>{module.department}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <table className={classes.gridTable}>
                                            <thead>
                                              <tr>
                                                <th>UPC</th>
                                                <th>Case</th>
                                                <th>Pack</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {module.items.map((item, miIdx) => (
                                                <Fragment key={`ModuleItem-${mIdx}-${item.upc}-${miIdx}`}>
                                                  <tr>
                                                    <td>{item.upc}</td>
                                                    <td>{item.cases.toFixed(2)}</td>
                                                    <td>{item.pack}</td>
                                                  </tr>
                                                  <tr>
                                                    <td colSpan={3}>{item.description}</td>
                                                  </tr>
                                                </Fragment>
                                              ))}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <div className={classes.footerContainer}>
                <Button
                  id={`export-${id}-submit`}
                  variant="rounded-sides"
                  className={classes.exportButton}
                  text={exporting ? 'Exporting ...' : 'Export'}
                  onClick={handleExport}
                  disabled={exportDisabled}
                  startIcon={exporting && <Spinner id={'export'} size={20} />}
                ></Button>
              </div>
            </>
          )}
        </div>
      </PageBody>
      <ConfirmationDialog
        title={'Unsaved changes'}
        message={`Are you sure you want to continue?`}
        open={showUnsavedChanges}
        onAccept={() => navigate(RouteEnum.Dashboard)}
        onCancel={() => setShowUnsavedChanges(false)}
        onClose={() => setShowUnsavedChanges(false)}
        acceptLabel="Yes"
        cancelLabel="No"
      ></ConfirmationDialog>
    </>
  );
}
