import { IWorkflowLog } from '../../models';
import { fetchGetJson, fetchPostJson, HttpErrorResponse } from '../contractHubApi';
import { WorkflowStatus } from '../../models/enums';

export const contractWorkflowService = {
  getWorkflowStatus,
  createWorkflowStatus,
};

async function getWorkflowStatus(contractId: number): Promise<IWorkflowLog | null | HttpErrorResponse> {
  const response = await fetchGetJson<IWorkflowLog>({ endpoint: `contract/${contractId}/workflow` });
  if (response instanceof HttpErrorResponse && response.status == 404) return null;
  return response;
}

async function createWorkflowStatus(contractId: number, status: WorkflowStatus, contractTermsId?: number): Promise<IWorkflowLog | HttpErrorResponse> {
  const response = await fetchPostJson<IWorkflowLog>({ endpoint: `contract/${contractId}/workflow/${status}?contractTermsId=${contractTermsId}` });
  return response;
}
