import { PricingGridInternalValidationIssueType } from '@dierbergs-markets/react-feature-library';
import { ValidationSeverity, ContractApiValidationIssueType, ValidationSource } from '../models/enums';

const FieldName = (param: IValidationIssueParam): string => param.displayField ?? param.field ?? 'Field';

export interface IValidationIssueParam<TData = any> {
  message?: string;
  scope: string;
  field?: string;
  displayField?: string;
  identifier?: any;
  severity?: ValidationSeverity;
  source: ValidationSource;
  type?: ContractApiValidationIssueType | PricingGridInternalValidationIssueType;
  data?: TData;
}

export interface IValidationIssueComparerParam extends IValidationIssueParam {
  input?: string;
  comparison?: string;
}

export class ValidationIssue<TData = any> {
  public message: string;
  public scope: string;
  public field?: string;
  public displayField?: string;
  public identifier?: any;
  public severity: ValidationSeverity;
  public source: ValidationSource;
  public type?: ContractApiValidationIssueType | PricingGridInternalValidationIssueType;
  public data?: TData;

  /**
   *
   * @param param IValidationIssueParam
   *
   */
  constructor(param: IValidationIssueParam<TData>) {
    if (!param.message) throw new Error('Message is required for ValidationIssue');

    this.message = param.message;
    this.scope = param.scope;
    this.field = param.field;
    this.identifier = param.identifier;
    this.severity = param.severity ?? ValidationSeverity.Error;
    this.displayField = param.displayField;
    this.data = param.data;
    this.source = param.source;
    this.type = param.type;
  }

  ToString(): string {
    if (!this.displayField && !this.field) return this.message;
    return `${this.displayField ?? this.field}: ${this.message}`;
  }

  static GreaterThan(param: IValidationIssueComparerParam) {
    return new ValidationIssue({ ...param, message: `${param.input} must be greater than ${param.comparison}.` });
  }

  static GreaterThanOrEqual(param: IValidationIssueComparerParam) {
    return new ValidationIssue({ ...param, message: `${param.input} must be greater than or equal to ${param.comparison}.` });
  }

  static LessThan(param: IValidationIssueComparerParam) {
    return new ValidationIssue({ ...param, message: `${param.input} must be less than ${param.comparison}.` });
  }

  static LessThanOrEqual(param: IValidationIssueComparerParam) {
    return new ValidationIssue({ ...param, message: `${param.input} must be less than or equal to ${param.comparison}.` });
  }

  static NonEmpty(param: IValidationIssueParam) {
    return new ValidationIssue({ ...param, message: `${FieldName(param)} must not be empty.` });
  }

  static NotFound(param: IValidationIssueParam) {
    return new ValidationIssue({ ...param, message: `${FieldName(param)} was not found.` });
  }

  static Required(param: IValidationIssueParam) {
    return new ValidationIssue({ ...param, message: `${FieldName(param)} is required.` });
  }
}
