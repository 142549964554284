import { MdErrorOutline, MdOutlineWarningAmber } from 'react-icons/md';
import { defaultColors } from '../../../../styles/variables';

interface TabIconProps {
  showError: boolean;
  showWarning: boolean;
}

export function TabIcon(props: TabIconProps) {
  const { showError, showWarning } = props;

  const errorIconStyle = {
    marginLeft: '0px !important',
    marginBottom: '10px',
    color: defaultColors.red,
    fontSize: '16px',
  };

  const warningIconStyle = {
    marginLeft: '4px',
    marginBottom: '10px',
    color: defaultColors.orange,
    fontSize: '16px',
  };

  if (!showError && !showWarning) {
    return null;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {showError && <MdErrorOutline style={errorIconStyle} />}
      {showWarning && <MdOutlineWarningAmber style={warningIconStyle} />}
    </div>
  );
}
