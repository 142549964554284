import { Box, Button, Tooltip } from '@mui/material';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from 'tss-react';
import { IContract, IContractItem, IContractTermForItem, IContractTerms, IItem, IItemSupplier, IStoreSelection } from '../../../../models';
import { DbgLinkButton } from '../../../components/buttons/DbgLinkButton';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import { DbgButtonSize, SortDirection, UpcDataGridFields, UserType, ValidationScopes } from '../../../../models/enums';
import { getRedButtonStyleProps, getTransparentButtonStyleProps, getWhiteButtonStyleProps } from '../../../../styles/themes';
import { DbgRoundedButton } from '../../../components/buttons/DbgRoundedButton';
import { format } from 'date-fns';
import UpcSelectionModal from './dialogs/UpcSelectionModal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '../../../../images/delete.svg';
import { StyledImg } from '../../../../styles/styled/StyledWrappedCommonElements';
import { UpcDataGridStyles } from './styles/UpcDataGridStyles';
import { defaultColors } from '../../../../styles/variables';
import { ArrayUtils } from '../../../../utilities/ArrayUtility';
import { DialogSharedStyles } from '../styles/DialogSharedStyles';
import DbgDialog from '../../../components/dialogs/DbgDialog';
import OverlappingContractsButton from './OverlappingContractsButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  DataGrid,
  DataGridColumnVisibilityMap,
  DataGridColDef,
  DataGridRenderBodyCellParams,
  DataGridValueGetterParams,
  DataGridValueSetterParams,
  DataGridEditableCellOverrideParams,
  DataGridSortDirection,
  NumberInput,
  Checkbox,
  DataGridHeaderColumnMenuItem,
  DataGridCellMessage,
} from '@dierbergs-markets/react-component-library';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '../../../../images/refresh.svg';
import { NumberToCurrencyString } from '../../../../utilities/NumberUtility';
import { v4 } from 'uuid';
import { toDataGridCellMessage } from '../../../../utilities/DataGridUtilities';
import { shallowEquals } from '../../../../utilities/ObjectUtilities';
import { useContractValidationStore } from '../../../../store/contract/contractValidationStore';
type FixedColumnNames = 'caseListCost' | 'suggestedRetailPrice' | 'suggestedRetailMultiple';

interface IProps {
  contract: IContract;
  contractTerms: IContractTerms;
  storeSelections: IStoreSelection;
  canModify: boolean;
  onTermEdit: (term: IContractTermForItem) => void;
  onContractItemsChange: (contractItems: IContractItem[]) => void;
  onNewPricingFromItemTerm: (itemTermUniqueId: string, startDate?: Date, endDate?: Date) => void;
  onAutoIncludeAssociatedItemsCheck: (checked: boolean) => void;
  contractLoading: boolean;
}

const UpcDataGrid = memo((props: IProps) => {
  const LOCALSTORAGE_COLUMN_VISIBILITY = 'upcDataGrid.preferences.colVisibility';

  const mounted = useRef(true);
  const { referenceData, user } = useApplicationContextState();

  const { styles } = UpcDataGridStyles;
  const { styles: dlgStyles } = DialogSharedStyles;

  const { css } = useStyles();

  const [showUpcSelectionModal, setShowUpcSelectionModal] = useState(false);
  const [showDeleteContractItemConfirmation, setShowDeleteContractItemConfirmation] = useState<boolean>(false);
  const [contractItemToDelete, setContractItemToDelete] = useState<number | undefined>();
  const {
    showValidationIssues,
    validationIssues,
    hasInternalUpcDataGridErrors,
    setHasInternalUpcDataGridErrors,
    hasInternalUpcDataGridWarnings,
    setHasInternalUpcDataGridWarnings,
  } = useContractValidationStore();

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const invokeTermEdit = (term: IContractTermForItem): void => {
    props.canModify && props.onTermEdit(term);
  };

  const defaultVisiblityMap: DataGridColumnVisibilityMap = getGridColumns().reduce((acc, cur, index) => {
    const fieldName = cur.field || `column_${index}`;

    let isVisible = true;

    const fieldsHiddenByDefault = [UpcDataGridFields.ItemPriceAssociation, UpcDataGridFields.ItemDepartment];

    if (fieldsHiddenByDefault.includes(fieldName)) isVisible = false;

    return { ...acc, [fieldName]: isVisible };
  }, {});

  const rawVisibilityMap = localStorage.getItem(LOCALSTORAGE_COLUMN_VISIBILITY);
  const [columnVisibility, setColumnVisibility] = useState<DataGridColumnVisibilityMap>(
    rawVisibilityMap ? JSON.parse(rawVisibilityMap) : defaultVisiblityMap
  );

  // DBGCH-3841 Fix
  const columns = getGridColumns();

  // 1) Build a new map for every column in `columns`.
  const updatedColumnVisibility = columns.reduce((acc, col, index) => {
    const fieldName = col.field || `column_${index}`;
    // Use the old setting if it exists; otherwise default is true (or false if you want).
    const oldValue = columnVisibility[fieldName];
    const isVisible = typeof oldValue === 'undefined' ? true : oldValue;
    return { ...acc, [fieldName]: isVisible };
  }, {} as DataGridColumnVisibilityMap);

  // 2) If updatedColumnVisibility differs from your current `columnVisibility`, set state & localStorage
  const sameAsOldMap = shallowEquals(updatedColumnVisibility, columnVisibility);
  useEffect(() => {
    if (!sameAsOldMap) {
      setColumnVisibility(updatedColumnVisibility);
      localStorage.setItem(LOCALSTORAGE_COLUMN_VISIBILITY, JSON.stringify(updatedColumnVisibility));
    }
    // Only run if columns or columnVisibility changed
  }, [columns, columnVisibility]);
  // End DBGCH-3841 Fix

  function getGridColumns(): DataGridColDef[] {
    if (!referenceData) return [];

    function isCellDisabled(termUnitOfMeasureId: number, isRandomWeight?: boolean) {
      return (
        (referenceData?.termTypeUnitsOfMeasure.byId[termUnitOfMeasureId].forRandomWeight && !isRandomWeight) ||
        (!referenceData?.termTypeUnitsOfMeasure.byId[termUnitOfMeasureId].forRandomWeight && isRandomWeight)
      );
    }

    function setEmptyColumnInputs(promoIndexPosition?: number, value?: number, fixedColumn?: FixedColumnNames) {
      if (value === undefined || isNaN(value)) return;
      if (props.contractTerms.contractItems.length === 0) return;

      const updatedContractItems = props.contractTerms.contractItems.map((ci) => {
        if (promoIndexPosition !== undefined && (ci.amounts[promoIndexPosition] === null || ci.amounts[promoIndexPosition] === undefined)) {
          const term = props.contractTerms.contractTermsForItem[promoIndexPosition];
          if (!isCellDisabled(term.termUnitOfMeasureId, ci.item?.isRandomWeight)) {
            return { ...ci, amounts: replaceValue(ci.amounts, promoIndexPosition, value) };
          }
        } else if (fixedColumn) {
          if (!ci[fixedColumn] && ci[fixedColumn] !== 0) {
            return { ...ci, [fixedColumn]: value };
          }
        }

        return ci;
      });

      props.onContractItemsChange(updatedContractItems);
    }

    function replaceValue<T>(array: T[], index: number, value: T): T[] {
      const updated = [...array];
      updated.splice(index, 1, value);
      return updated;
    }

    function getPromoColumnHeaderMenuItems(itemTerm: IContractTermForItem): DataGridHeaderColumnMenuItem[] {
      const menuItems: DataGridHeaderColumnMenuItem[] = [];

      if (user?.userType === UserType.Internal) {
        menuItems.push({
          name: 'Create Pricing',
          itemIcon: <AttachMoneyIcon />,
          onSelect: () => {
            props.onNewPricingFromItemTerm(itemTerm.uniqueId, itemTerm.startDate, itemTerm.endDate);
          },
        });
      }

      return menuItems;
    }

    const CreateDateHeader = (promo: IContractTermForItem) => {
      if (!promo.startDate || !promo.endDate) {
        return (
          <DbgLinkButton id={`PromoMissingDates${promo.uniqueId}`} onClick={() => invokeTermEdit(promo)} sx={styles.promoMissingDates}>
            <ErrorOutlineIcon sx={styles.promoMissingDatesIcon} /> Missing Date(s)
          </DbgLinkButton>
        );
      }
      const dateError = validationIssues
        .filter(
          (q) =>
            q.scope === ValidationScopes.ContractTermsForItem && ['StartDate', 'EndDate'].includes(q.field ?? '') && q.identifier === promo.uniqueId
        )
        .map((e) => e.message)
        .join(' ');
      if (dateError) {
        return (
          <DbgLinkButton
            id={`PromoInvalidDates${promo.uniqueId}`}
            onClick={() => invokeTermEdit(promo)}
            sx={styles.promoMissingDates}
            title={dateError}
          >
            <ErrorOutlineIcon sx={styles.promoMissingDatesIcon} /> Invalid Date(s)
          </DbgLinkButton>
        );
      }

      return `${format(new Date(promo.startDate), 'MM/dd/yy')} - ${format(new Date(promo.endDate), 'MM/dd/yy')}`;
    };

    const upcImageStyle = { width: '16px', height: '18px', marginLeft: '5px', marginRight: '5px' };
    const generalInfoColumns: DataGridColDef[] = [
      {
        headerName: '',
        field: UpcDataGridFields.Delete,
        type: 'string',
        width: 40,
        tabIndex: -1,
        hideable: false,
        columnBodyCss: { ...styles.columnOverrides.deleteBodyCell, ...styles.columnOverrides.itemBodyCell },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number>) => {
          if (!props.canModify || params.row.isShipperContainedItem) return <></>;
          return (
            <Button
              data-button-type={'delete'}
              sx={styles.buttons.deleteContractItem}
              id={`deleteContractItem_${params.row.sku}`}
              onClick={() => handleContractItemDelete(params.row.sku)}
              tabIndex={-1}
            >
              <StyledImg src={DeleteIcon} sx={styles.buttons.deleteContractItem.icon} />
            </Button>
          );
        },
      },
      {
        headerName: `Item UPC (${props.contractTerms.contractItems.length})`,
        field: UpcDataGridFields.ItemUpc,
        type: 'string',
        width: 145,
        tabIndex: -1,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
        sortable: true,
        hideable: false,
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number, IContractItem>) => {
          if (!params.value) return 'Unknown Id';

          function getTooltip() {
            if (!params.row.item?.discontinuedDate) return '';

            const verbTense = params.row.item.discontinuedDate <= new Date() ? 'was' : 'will be';
            return `Item ${params.row.item.upc} ${verbTense} discontinued on ${params.row.item.discontinuedDate.toDateString()}.`;
          }

          const cellHasErrors = validationIssues.some(
            (vi) => vi.scope === ValidationScopes.ContractItem && vi.field === UpcDataGridFields.ItemUpc && vi.identifier === params.rowId
          );
          const tooltip = cellHasErrors && showValidationIssues ? '' : getTooltip();

          return (
            <Box style={{ display: 'flex' }}>
              {params.value}
              {params.row.isSystemAdded && <StyledImg src={RefreshIcon} sx={upcImageStyle} />}
              {params.row.item?.discontinuedDate && (
                <Tooltip title={tooltip}>
                  <ScheduleIcon sx={upcImageStyle} />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
      {
        headerName: `Order Code`,
        field: UpcDataGridFields.ItemOrderCode,
        type: 'string',
        width: 115,
        tabIndex: -1,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
        sortable: true,
        hideable: true,
      },
      {
        headerName: 'Department',
        field: UpcDataGridFields.ItemDepartment,
        type: 'string',
        width: 120,
        tabIndex: -1,
        sortable: true,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
      },
      {
        headerName: 'Price Association',
        field: UpcDataGridFields.ItemSuppliers,
        type: 'string',
        width: 150,
        tabIndex: -1,
        sortable: true,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<IItemSupplier[] | undefined, IContractItem>) => {
          const priceAssociationCode =
            (params.value && params.value.find && params.value.find((i) => i.id === props.contract.terms.supplier?.id)?.priceAssociationCode) ?? '';
          return <div>{`${priceAssociationCode}`}</div>;
        },
      },
      {
        headerName: 'Pack',
        type: 'string',
        field: UpcDataGridFields.ItemQuantityPerPack,
        width: 70,
        tabIndex: -1,
        sortable: true,
        hideable: false,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
      },
      {
        headerName: 'Size',
        type: 'string',
        field: UpcDataGridFields.ItemSize,
        width: 75,
        tabIndex: -1,
        sortable: true,
        hideable: false,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<IItem, IContractItem>) => {
          return <div>{`${params.value} ${params.row.item?.unitOfMeasure ? params.row.item?.unitOfMeasure?.toLowerCase() : ''}`}</div>;
        },
      },
      {
        headerName: 'Description',
        type: 'string',
        field: UpcDataGridFields.ItemDescription,
        flex: 1,
        minWidth: 125,
        tabIndex: -1,
        hideable: false,
        sortable: true,
        resizable: true,
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<string, IContractItem>) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              {params.collapsible && (
                <div
                  onClick={() => {
                    if (params.setCollapsed) params.setCollapsed(!params.isCollapsed);
                  }}
                >
                  {params.isCollapsed ? (
                    <KeyboardArrowDownIcon sx={{ color: defaultColors.blue }} />
                  ) : (
                    <KeyboardArrowUpIcon sx={{ color: defaultColors.blue }} />
                  )}
                </div>
              )}
              <Box
                style={{
                  marginLeft: params.row.isShipperContainedItem ? '20px' : '0px',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexGrow: 100,
                  fontWeight: 500,
                  color: params.collapsible ? defaultColors.blue : defaultColors.black,
                }}
                title={params.value}
              >
                {params.value}
              </Box>
            </Box>
          );
        },
      },
    ];

    const inputColumns: DataGridColDef[] = [
      {
        headerName: 'Case List Cost',
        field: UpcDataGridFields.CaseListCost,
        type: 'number',
        width: 150,
        editable: props.canModify,
        findReplaceEnabled: true,
        hideable: false,
        columnBodyCss: styles.columnOverrides.editableBodyCell,
        columnHeaderCss: {
          root: styles.columnOverrides.headerInputCell,
          menu: styles.columnOverrides.headerInputCellMenuIcon,
        },
        renderHeaderCellContent: () => {
          return (
            <Box sx={styles.headerInputAndLabelContainer}>
              <Box sx={styles.headerNumberInputLabel}>Case List Cost</Box>
              {props.canModify && (
                <NumberInput
                  id={`caseListCostHeaderInput`}
                  classes={{
                    root: css(styles.headerNumberInputRoot),
                  }}
                  onChangeCompleted={(value: any) => setEmptyColumnInputs(undefined, value, 'caseListCost')}
                  clearOnEnter
                  clearOnBlur
                  decimals={2}
                  prefix="$"
                  tabIndex={-1}
                />
              )}
            </Box>
          );
        },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number | undefined>) => {
          if (params.row.isShipperContainedItem) {
            return <></>;
          }
          let amount: number | undefined = undefined;
          if (params.value != undefined && params.value.toString() != '') amount = parseFloat(`${params.value}`);

          return CreateInputBodyCell(amount);
        },
      },
    ];

    const promosColumns: DataGridColDef[] =
      props.contractTerms.contractTermsForItem.map((itemTerm, itemTermIndex) => {
        function commonGetter(params: DataGridValueGetterParams<IContractItem>) {
          if (isCellDisabled(itemTerm.termUnitOfMeasureId, params.row.item?.isRandomWeight)) {
            return 0;
          }
          if (params.row.isShipperContainedItem) {
            return 0;
          }
          return params.row.amounts[itemTermIndex];
        }

        function commonSetter(params: DataGridValueSetterParams<number | undefined, IContractItem>) {
          let value: number | undefined = undefined;
          if (params.value != undefined && params.value.toString() != '') {
            value = parseFloat(`${params.value}`);
          }

          if (isCellDisabled(itemTerm.termUnitOfMeasureId, params.row.item?.isRandomWeight)) {
            value = 0;
          }

          const amounts = [...params.row.amounts];

          if (amounts[itemTermIndex] === value) return params.row;
          amounts[itemTermIndex] = value;
          return { ...params.row, amounts };
        }

        const colDef: DataGridColDef = {
          headerName: itemTerm?.termTypeId ? referenceData.termTypes.byId[itemTerm.termTypeId].name : '',
          findReplaceEnabled: true,
          field: UpcDataGridFields.ItemTerm(itemTerm?.termTypeId || 0, itemTermIndex),
          type: 'number',
          width: 195,
          editable: props.canModify,
          hideable: false,
          columnBodyCss: styles.columnOverrides.editableBodyCell,
          headerMenuItems: getPromoColumnHeaderMenuItems(itemTerm),
          columnHeaderCss: {
            root: styles.columnOverrides.headerInputCell,
            menu: styles.columnOverrides.headerInputCellMenuIcon,
          },
          renderHeaderCellContent: () => {
            const termTypeBox = (
              <>
                <Box sx={styles.buttons.promoModal.container}>
                  <Box data-label={'termType'}>
                    {props.canModify && <EditOutlinedIcon sx={styles.buttons.promoModal.editIcon} />}
                    {itemTerm.termTypeId && referenceData.termTypes.byId[itemTerm.termTypeId].name}
                  </Box>
                </Box>
                <Box sx={styles.buttons.promoModal.uom} data-label={'uom'}>
                  {itemTerm.termUnitOfMeasureId && `By ${referenceData.termTypeUnitsOfMeasure.byId[itemTerm.termUnitOfMeasureId].name}`}
                </Box>
              </>
            );
            return (
              <>
                <Box
                  key={itemTermIndex}
                  sx={{
                    paddingTop: '16px',
                    width: '100%',
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingLeft: '2px',
                    paddingRight: '2px',
                  }}
                >
                  {(props.canModify && (
                    <DbgLinkButton
                      id={`UpcGridTerm${itemTerm.uniqueId}`}
                      sx={[styles.rightAlign, styles.buttons.promoModal, styles.buttons.promoModal.editable]}
                      onClick={() => invokeTermEdit(itemTerm)}
                    >
                      {termTypeBox}
                    </DbgLinkButton>
                  )) || <Box sx={[styles.rightAlign, styles.buttons.promoModal]}>{termTypeBox}</Box>}
                  <Box sx={styles.promoInputAndLabelWrapper}>
                    <Box sx={[styles.headerNumberInputLabel, styles.promoHeaderDates]}>&nbsp;&nbsp;{CreateDateHeader(itemTerm)}</Box>

                    {props.canModify && (
                      <NumberInput
                        id={`termAmountHeaderInput_${itemTermIndex}`}
                        classes={{
                          root: css(styles.headerNumberInputRoot),
                        }}
                        onChangeCompleted={(value: any) => setEmptyColumnInputs(itemTermIndex, value)}
                        clearOnEnter
                        clearOnBlur
                        decimals={2}
                        prefix="$"
                        tabIndex={-1}
                      />
                    )}
                  </Box>
                </Box>
              </>
            );
          },
          renderBodyCellContent: (params: DataGridRenderBodyCellParams<number | null, IContractItem>) => {
            if (params.row.isShipperContainedItem) {
              return <></>;
            }
            if (isCellDisabled(itemTerm?.termUnitOfMeasureId, params.row.item?.isRandomWeight)) {
              return CreateDisabledBodyCell();
            }
            const amount: number | undefined = params.row.amounts[itemTermIndex];
            return CreateInputBodyCell(amount);
          },
          editableCellOverride: (params: DataGridEditableCellOverrideParams<number, IContractItem>) => {
            //cell is editable if it is not disabled
            return { isEditable: !isCellDisabled(itemTerm.termUnitOfMeasureId, params.row.item?.isRandomWeight) };
          },
          valueGetter: commonGetter,
          valueSetter: commonSetter,
          findValueOverride: commonGetter,
          replaceValueOverride: commonSetter,
        };

        return colDef;
      }) || [];

    const srpColumns: DataGridColDef[] = [
      {
        headerName: 'Suggested Retail Multiple',
        findReplaceEnabled: true,
        field: UpcDataGridFields.SuggestedRetailMultiple,
        type: 'number',
        width: 105,
        editable: props.canModify,
        decimalPlaces: 0,
        hideable: false,
        columnBodyCss: styles.columnOverrides.editableBodyCell,
        columnHeaderCss: {
          root: styles.columnOverrides.headerInputCell,
          menu: styles.columnOverrides.headerInputCellMenuIcon,
        },
        suffix: '/',
        prefix: '',
        renderHeaderCellContent: () => {
          return (
            <Box sx={styles.headerInputAndLabelContainer}>
              <Box sx={styles.headerNumberInputLabel}>Multiple</Box>
              {props.canModify && (
                <NumberInput
                  id={`suggestedRetailMultipleHeaderInput`}
                  classes={{
                    root: css(styles.headerNumberInputRoot),
                  }}
                  onChangeCompleted={(value: any) => setEmptyColumnInputs(undefined, value, 'suggestedRetailMultiple')}
                  clearOnEnter
                  clearOnBlur
                  decimals={0}
                  allowLeadingZeros={false}
                  suffix="/"
                  tabIndex={promosColumns?.length ?? 0}
                />
              )}
            </Box>
          );
        },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number | undefined>) => {
          if (params.row.isShipperContainedItem) {
            return <></>;
          }
          let amount: number | undefined = undefined;
          if (params.value != undefined && params.value.toString() != '') amount = parseInt(`${params.value}`);

          return CreateInputBodyIntCell(amount);
        },
      },
      {
        headerName: 'Suggested Retail Price',
        findReplaceEnabled: true,
        field: UpcDataGridFields.SuggestedRetailPrice,
        type: 'number',
        width: 195,
        editable: props.canModify,
        hideable: false,
        columnBodyCss: styles.columnOverrides.editableBodyCell,
        columnHeaderCss: {
          root: styles.columnOverrides.headerInputCell,
          menu: styles.columnOverrides.headerInputCellMenuIcon,
        },
        renderHeaderCellContent: () => {
          return (
            <Box sx={styles.headerInputAndLabelContainer}>
              <Box sx={styles.headerNumberInputLabel}>Suggested Retail Price</Box>
              {props.canModify && (
                <NumberInput
                  id={`suggestedRetailPriceHeaderInput`}
                  classes={{
                    root: css(styles.headerNumberInputRoot),
                  }}
                  onChangeCompleted={(value: any) => setEmptyColumnInputs(undefined, value, 'suggestedRetailPrice')}
                  clearOnEnter
                  clearOnBlur
                  decimals={2}
                  prefix="$"
                  tabIndex={promosColumns?.length ?? 0 + 1}
                />
              )}
            </Box>
          );
        },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number | undefined>) => {
          if (params.row.isShipperContainedItem) {
            return <></>;
          }
          let amount: number | undefined = undefined;
          if (params.value != undefined && params.value.toString() != '') amount = parseFloat(`${params.value}`);

          return CreateInputBodyCell(amount);
        },
      },
    ];

    return [...generalInfoColumns, ...inputColumns, ...promosColumns, ...srpColumns];
  }

  function handleUpcAdded(contractItems: IContractItem[]) {
    setShowUpcSelectionModal(false);
    const addedItems = contractItems.map((ci) => {
      return {
        ...ci,
        caseListCost: undefined,
        suggestedRetailPrice: undefined,
        suggestedRetailMultiple: undefined,
        amounts: (ci.amounts = Array<number | undefined>(props.contractTerms.contractTermsForItem.length).fill(undefined)), //predefine empty array
        uniqueId: ci.uniqueId ?? v4(),
        childData: ci.childData
          ? ci.childData.map((child) => ({
              ...child,
              caseListCost: undefined,
              suggestedRetailPrice: undefined,
              suggestedRetailMultiple: undefined,
              amounts: [undefined],
              uniqueId: child.uniqueId ?? v4(),
            }))
          : [],
      };
    });
    const distinctItems = ArrayUtils.distinct([...props.contractTerms.contractItems, ...addedItems], (a, b) => a.sku === b.sku);
    props.onContractItemsChange(distinctItems);
  }

  function handleContractItemDelete(sku: number): void {
    setContractItemToDelete(sku);
    setShowDeleteContractItemConfirmation(true);
  }

  function handleContractItemModalDeleteConfirm() {
    if (!contractItemToDelete) return;
    const items = [...props.contractTerms.contractItems.filter((i) => i.sku !== contractItemToDelete)];
    props.onContractItemsChange(items);
    setShowDeleteContractItemConfirmation(false);
  }

  function handleContractItemRowUpdate(updatedRow: IContractItem) {
    const items = props.contractTerms.contractItems.map((ci) => (ci.sku === updatedRow.sku ? updatedRow : ci));

    props.onContractItemsChange(items);
  }

  function handleContractItemsRowUpdate(rows: IContractItem[]) {
    props.onContractItemsChange(
      props.contractTerms.contractItems.map((ci) => {
        const row = rows.find((item) => item.sku === ci.sku);
        if (row && !row.isShipperContainedItem) {
          return row;
        } else {
          return {
            ...ci,
            childData: ci.childData?.map((a) => {
              const row = rows.find((item) => item.sku === a.sku);
              return row ? row : a;
            }),
          };
        }
      })
    );
  }

  function handleColumnVisibilityChange(map: DataGridColumnVisibilityMap) {
    setColumnVisibility(map);
    localStorage.setItem(LOCALSTORAGE_COLUMN_VISIBILITY, JSON.stringify(map));
  }

  const handleInternalValidationComplete = useCallback(
    (messages: DataGridCellMessage[]) => {
      const hasErrors = messages.filter((m) => m.isError()).length > 0;
      const hasWarnings = messages.filter((m) => m.isWarning()).length > 0;
      if (hasInternalUpcDataGridErrors !== hasErrors) {
        setHasInternalUpcDataGridErrors(hasErrors);
      }
      if (hasInternalUpcDataGridWarnings !== hasWarnings) {
        setHasInternalUpcDataGridWarnings(hasWarnings);
      }
    },
    [hasInternalUpcDataGridErrors, hasInternalUpcDataGridWarnings]
  );

  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.actionsContainer}>
          <Box sx={styles.actions}>
            {props.canModify && (
              <DbgRoundedButton
                id="AddUpc"
                dbgButtonSize={DbgButtonSize.Large}
                styleProps={getTransparentButtonStyleProps()}
                sx={styles.buttons.addUpc}
                onClick={() => setShowUpcSelectionModal(true)}
              >
                + New Item
              </DbgRoundedButton>
            )}
            <Checkbox
              id={'AutoAddItems'}
              label={'Include items in the same price association'}
              checked={props.contractTerms.autoAddAssociatedItems || false}
              onChange={(checked) => props.onAutoIncludeAssociatedItemsCheck(checked)}
              disabled={!props.canModify}
            />
            <OverlappingContractsButton contract={props.contract} />
          </Box>
        </Box>
        <DataGrid
          id={'contract-items-grid'}
          rows={[...props.contractTerms.contractItems]}
          getRowId={(r: IContractItem) => r.sku}
          columns={getGridColumns()}
          bodyRowHeight={48}
          messages={validationIssues.map(toDataGridCellMessage)}
          headerRowHeight={150}
          showBodyCellRightBorder
          cssOverrides={{
            root: styles.gridOverrides.root,
            bodyRow: styles.gridOverrides.bodyRow,
            header: styles.gridOverrides.header,
            headerCell: styles.gridOverrides.headerCell,
          }}
          processRowUpdate={handleContractItemRowUpdate}
          processMultiRowUpdate={handleContractItemsRowUpdate}
          preferences={{
            columns: {
              visibility: columnVisibility,
            },
          }}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          onInternalValidationComplete={handleInternalValidationComplete}
          showInputValidationErrors={showValidationIssues || hasInternalUpcDataGridErrors}
          defaultSort={[{ column: UpcDataGridFields.ItemUpc, direction: SortDirection.ASC as DataGridSortDirection }]}
          isLoading={props.contractLoading}
        />
      </Box>

      {showUpcSelectionModal && (
        <UpcSelectionModal
          id={'UpcSelection'}
          supplierId={props.contractTerms.supplier?.id}
          storeSelections={props.contractTerms.stores}
          supplier={props.contractTerms.supplier}
          open={showUpcSelectionModal}
          onAddToContract={handleUpcAdded}
          onCancel={() => setShowUpcSelectionModal(false)}
        />
      )}
      <DbgDialog
        id="deleteContractItemDialog"
        open={showDeleteContractItemConfirmation}
        cancelText="Cancel"
        sx={dlgStyles.dialogContent}
        confirmButtonTheme={getRedButtonStyleProps()}
        cancelButtonTheme={{ ...getWhiteButtonStyleProps(), width: '150px !important' }}
        confirmText="Yes, Delete"
        onConfirm={handleContractItemModalDeleteConfirm}
        onCancel={() => {
          setShowDeleteContractItemConfirmation(false);
          setContractItemToDelete(undefined);
        }}
        title="Are you sure you want to delete this item?"
      ></DbgDialog>
    </>
  );
});

function CreateInputBodyCell(value: number | undefined) {
  const { styles } = UpcDataGridStyles;

  return (
    <Box sx={styles.promoInputCell}>
      <Box sx={styles.promoInputCell.startAdornment}>$</Box>
      <Box>{value !== undefined && value !== null && NumberToCurrencyString(value)}</Box>
    </Box>
  );
}

function CreateInputBodyIntCell(value: number | undefined) {
  const { styles } = UpcDataGridStyles;

  return (
    <Box sx={styles.promoInputCell}>
      <Box>{value !== undefined && value !== null && value}</Box>
      <Box sx={styles.promoInputCell.endAdornment}>/</Box>
    </Box>
  );
}

function CreateDisabledBodyCell() {
  const { styles } = UpcDataGridStyles;

  return (
    <Tooltip title={'Disabled For random weight'}>
      <Box sx={styles.promoInputCell}>
        <Box sx={styles.promoInputCell.startAdornment}>$</Box>
        <Box>{0}</Box>
      </Box>
    </Tooltip>
  );
}

export default UpcDataGrid;
