import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { SharedAdminStyles } from '../../styles/SharedAdminStyles';
import {
  ArrayUtils,
  AutoComplete,
  Button,
  ConfirmationDialog,
  DataGrid,
  DataGridColDef,
  DataGridFilterField,
  DataGridRenderBodyCellParams,
  DataGridRowModel,
  DataGridSortField,
  DeleteButton,
  IconButton,
  Spinner,
} from '@dierbergs-markets/react-component-library';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Permission, UserType } from '../../../../../models/enums';
import { RouteEnum } from '../../../../layout/PageRouter';
import { customerService, accountService, accountUserService } from '../../../../../services';
import { HttpErrorResponse } from '../../../../../services/contractHubApi';
import { useSnackbar } from 'notistack';
import {
  ICustomer,
  IExternalAccountDetails,
  IExternalAccountCustomer,
  IExternalAccountName,
  IExternalAccountUser,
  IUserGroup,
} from '../../../../../models';
import EditIcon from '@mui/icons-material/Edit';
import { defaultColors } from '../../../../../styles/variables';
import { useStyles } from 'tss-react';
import { IoMdAdd } from 'react-icons/io';
import EditExternalAccountModal from '../components/EditExternalAccountModal';
import EditExternalAccountUserModal from '../components/EditExternalAccountUserModal';
import PageBody from '../../../../layout/components/PageBody';
import PageHeader from '../../../../layout/components/PageHeader';
import { useDataGridFilters } from '../../../../../utilities/ReactUtilities';

interface IUserSearchQuery {
  name?: string;
  groupName?: string;
  emailAddress?: string;
  phoneNumber?: string;
}

export default function AdminExternalAccountDetailPage(): JSX.Element {
  const navigate = useNavigate();
  const { user } = useApplicationContextState();
  const { externalAccountId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { css } = useStyles();

  const [account, setAccount] = useState<IExternalAccountName | undefined>(undefined);
  const [accountUsers, setAccountUsers] = useState<IExternalAccountUser[]>([]);
  const [externalAccountCustomers, setExternalAccountCustomers] = useState<IExternalAccountCustomer[]>([]);
  const [userGroups, setUserGroups] = useState<IUserGroup[] | null>(null);

  const [accountLoading, setAccountLoading] = useState<boolean>(false);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [customersLoading, setCustomersLoading] = useState<boolean>(false);

  const [addCustomerLoading, setAddCustomerLoading] = useState<boolean>(false);
  const [editAccountLoading, setEditAccountLoading] = useState<boolean>(false);
  const [userDeleting, setUserDeleting] = useState<boolean>(false);
  const [customerDeleting, setCustomerDeleting] = useState<boolean>(false);

  const [canManageAccount, setCanManageAccount] = useState<boolean>(false);
  const [canDeleteAccount] = useState<boolean>(false); // This will be implemented after the endpoint is created, in the future

  const [showEditAccountModal, setShowEditAccountModal] = useState<boolean>(false);
  const [showEditExternalAccountUserModal, setShowEditExternalAccountUserModal] = useState<boolean>(false);

  const [externalAccountUserToEdit, setExternalAccountUserToEdit] = useState<IExternalAccountUser | null>(null);
  const [selectedCustomerToAdd, setSelectedCustomerToAdd] = useState<ICustomer | undefined>();

  const [customerToDelete, setCustomerToDelete] = useState<IExternalAccountCustomer | null>(null);
  const [userToDelete, setUserToDelete] = useState<IExternalAccountUser | null>(null);
  const [showCustomerDeleteModal, setShowCustomerDeleteModel] = useState<boolean>(false);
  const [showUserDeleteModal, setShowUserDeleteModel] = useState<boolean>(false);
  const [userSearchQuery, setUserSearchQuery] = useState<IUserSearchQuery>({});

  const [showAccountDeleteModal, setShowAccountDeleteModal] = useState<boolean>(false);

  const [filterCustomersFunc, setDataGridFilterCustomers] = useDataGridFilters('accounts-customers-data-grid');

  const [responseUsersData, setResponseUsersData] = useState<IExternalAccountUser[]>([]);
  const [userGridSortFields, setUserGridSortFields] = useState<DataGridSortField[]>([]);
  const [responseCustomersData, setResponseCustomersData] = useState<IExternalAccountCustomer[]>([]);
  const [customerGridSortFields, setCustomerGridSortFields] = useState<DataGridSortField[]>([]);
  const { styles } = SharedAdminStyles;

  useEffect(() => {
    setAccountUsers(responseUsersData);
  }, [responseUsersData]);

  useEffect(() => {
    setExternalAccountCustomers(filterCustomersFunc ? responseCustomersData.filter(filterCustomersFunc) : responseCustomersData);
  }, [responseCustomersData, setResponseCustomersData]);

  useEffect(() => {
    loadAccountUsers();
  }, [userSearchQuery]);

  useEffect(() => {
    loadAccountCustomers();
  }, [filterCustomersFunc]);

  // Data loader methods
  const loadAccount = async () => {
    if (!canManageAccount) return;
    setAccountLoading(true);
    const response = await accountService.getAccount(Number(externalAccountId));
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to retrieve account.', { variant: 'error' });
      setAccountLoading(false);
    } else {
      setAccount(response);
      setAccountLoading(false);
    }
  };

  const loadAccountUsers = async () => {
    setUsersLoading(true);
    const response = await accountUserService.searchAccountUsers(
      Number(externalAccountId),
      userSearchQuery.name,
      userSearchQuery.groupName,
      userSearchQuery.emailAddress,
      userSearchQuery.phoneNumber
    );
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to retrieve users for this account.', { variant: 'error' });
      setUsersLoading(false);
    } else {
      sortAndSetResponseUsersData(response, userGridSortFields);
      setUsersLoading(false);
    }
  };

  const sortAndSetResponseUsersData = (users: IExternalAccountUser[], fields: DataGridSortField[]) => {
    const translatedFields: DataGridSortField[] =
      fields.length > 0
        ? fields.flatMap((f) =>
            f.column === 'name'
              ? [
                  { column: 'firstName', direction: f.direction },
                  { column: 'lastName', direction: f.direction },
                ]
              : [f]
          )
        : [{ column: 'userId', direction: 'ASC' }];
    setResponseUsersData(ArrayUtils.orderBy([...users], translatedFields));
  };

  const sortAndSetResponseCustomersData = (customers: IExternalAccountCustomer[], fields: DataGridSortField[]) => {
    const sortFields: DataGridSortField[] = fields.length > 0 ? fields : [{ column: 'customerId', direction: 'ASC' }];
    setResponseCustomersData(ArrayUtils.orderBy([...customers], sortFields));
  };

  const handleUserSortChange = (fields: DataGridSortField[]) => {
    setUserGridSortFields(fields);
    sortAndSetResponseUsersData(responseUsersData, fields);
  };

  const handleCustomerGridSortChange = (fields: DataGridSortField[]) => {
    setCustomerGridSortFields(fields);
    sortAndSetResponseCustomersData(responseCustomersData, fields);
  };

  const loadAccountCustomers = async () => {
    if (!canManageAccount) return;
    setCustomersLoading(true);
    const customerResponse = await accountService.getAccountCustomers(Number(externalAccountId));

    if (customerResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to retrieve customers for this account.', { variant: 'error' });
      setCustomersLoading(false);
    } else {
      sortAndSetResponseCustomersData(customerResponse, customerGridSortFields);
      setCustomersLoading(false);
    }
  };

  const loadUserGroups = async () => {
    const userGroupsResponse = await accountUserService.getAccountUserGroups();
    if (userGroupsResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to retrieve user groups for this account.', { variant: 'error' });
    } else {
      setUserGroups(userGroupsResponse);
    }
  };

  const loadAccountData = async () => {
    await Promise.all([loadAccountUsers(), loadAccountCustomers(), loadUserGroups()]);
  };

  const searchCustomers = async (query: string): Promise<HttpErrorResponse | ICustomer[]> => {
    const response = await customerService.searchCustomerId(query);
    if (!(response instanceof HttpErrorResponse)) {
      return response.filter((c) => c.isActive);
    }

    return response;
  };

  // Handler methods
  const handleBackToAdmin = () => {
    if (user?.userType === UserType.External) {
      navigate(RouteEnum.Dashboard);
    } else {
      navigate(RouteEnum.AdminExternalAccounts);
    }
  };

  const handleEditAccountName = () => {
    setShowEditAccountModal(true);
  };

  const handleAddCustomer = async () => {
    if (!selectedCustomerToAdd) return;

    const customerAlreadyExists =
      externalAccountCustomers && externalAccountCustomers.findIndex((c) => c.customerId === selectedCustomerToAdd.customerId) > -1;

    if (customerAlreadyExists) {
      enqueueSnackbar('Customer has already been added.', { variant: 'info' });
      setSelectedCustomerToAdd(undefined);
      return;
    }

    setAddCustomerLoading(true);

    const successResponse = await accountService.createAccountCustomer(Number(externalAccountId), selectedCustomerToAdd.customerId);
    if (successResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to add customer.', { variant: 'error' });
      setAddCustomerLoading(false);
      return;
    }

    await loadAccountCustomers();
    enqueueSnackbar('Customer successfully added.', { variant: 'success' });
    setAddCustomerLoading(false);
    setSelectedCustomerToAdd(undefined);
  };

  const handleAccountUpdate = async (editedAccount: IExternalAccountName) => {
    setEditAccountLoading(true);
    const successResponse = await accountService.updateAccount(editedAccount.externalAccountId, editedAccount.name);
    setEditAccountLoading(false);
    if (successResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to update account.', { variant: 'error' });
      setEditAccountLoading(false);
      return;
    }

    setAccount(editedAccount);
    setShowEditAccountModal(false);
    enqueueSnackbar('Account successfully updated.', { variant: 'success' });
  };

  const handleEditAccountClose = () => {
    setShowEditAccountModal(false);
  };

  const handleDeleteAccountPrompt = () => {
    setShowAccountDeleteModal(true);
  };

  const handleAccountDeleteConfirm = async () => {
    enqueueSnackbar('This will delete the account.', { variant: 'info' }); // TODO
    // const deleteResponse = await accountService.delete(Number(externalAccountId));
    // if (deleteResponse instanceof HttpErrorResponse || !deleteResponse) {
    //   enqueueSnackbar('Unable to remove account.', { variant: 'error' });
    // } else {
    //   enqueueSnackbar('Account successfully deleted.', { variant: 'success' });
    // }

    setShowAccountDeleteModal(false);
    handleBackToAdmin();
  };

  const handleAccountDeleteCancel = () => {
    setShowAccountDeleteModal(false);
  };

  const handleAddUserPrompt = () => {
    if (!externalAccountId) return;

    setExternalAccountUserToEdit({
      userId: 0,
      externalAccountId: Number(externalAccountId),
      userGroupId: 0,
      firstName: '',
      lastName: '',
      emailAddress: '',
    });
    setShowEditExternalAccountUserModal(true);
  };

  const handleUserSelected = (row: DataGridRowModel<IExternalAccountUser>) => {
    setShowEditExternalAccountUserModal(true);
    setExternalAccountUserToEdit(row);
  };

  const handleUserUpdate = async () => {
    setExternalAccountUserToEdit(null);
    setShowEditExternalAccountUserModal(false);
    await loadAccountUsers();
  };

  const handleUserUpdateCancel = () => {
    setExternalAccountUserToEdit(null);
    setShowEditExternalAccountUserModal(false);
  };

  const handleCustomerDeletePrompt = (customer: IExternalAccountCustomer) => {
    setCustomerToDelete(customer);
    setShowCustomerDeleteModel(true);
  };

  const handleUserDeletePrompt = (user: IExternalAccountUser) => {
    setUserToDelete(user);
    setShowUserDeleteModel(true);
  };

  const handleCustomerDeleteConfirm = async () => {
    if (!customerToDelete) return;

    setCustomerDeleting(true);
    const deleteResponse = await accountService.deleteAccountCustomer(Number(externalAccountId), customerToDelete.customerId);
    if (deleteResponse instanceof HttpErrorResponse || !deleteResponse) {
      enqueueSnackbar('Unable to remove customer.', { variant: 'error' });
    } else {
      enqueueSnackbar('Customer successfully deleted.', { variant: 'success' });
      await loadAccountCustomers();
      setCustomerDeleting(false);
    }

    setShowCustomerDeleteModel(false);
    setCustomerToDelete(null);
  };

  const handleUserDeleteConfirm = async () => {
    if (!userToDelete) return;

    setUserDeleting(true);
    const deleteResponse = await accountUserService.deleteAccountUser(Number(externalAccountId), userToDelete.userId);
    if (deleteResponse instanceof HttpErrorResponse || !deleteResponse) {
      enqueueSnackbar('Unable to remove user.', { variant: 'error' });
    } else {
      enqueueSnackbar('User successfully deleted.', { variant: 'success' });
      await loadAccountUsers();
      setUserDeleting(false);
    }

    setShowUserDeleteModel(false);
    setUserToDelete(null);
  };

  const handleCustomerDeleteCancel = () => {
    setCustomerToDelete(null);
    setShowCustomerDeleteModel(false);
  };

  const handleUserDeleteCancel = () => {
    setUserToDelete(null);
    setShowUserDeleteModel(false);
  };

  function formatPhoneNumber(numberStr?: string) {
    if (!numberStr || numberStr.length !== 10 || !/^\d+$/.test(numberStr)) {
      return;
    }
    const areaCode = numberStr.slice(0, 3);
    const prefix = numberStr.slice(3, 6);
    const lastFour = numberStr.slice(6);
    return `(${areaCode}) ${prefix}-${lastFour}`;
  }

  // useEffect methods
  useEffect(() => {
    if (!account) return;
    loadAccountData();
  }, [account, canManageAccount]);

  useEffect(() => {
    setCanManageAccount(user?.userType === UserType.Internal && user?.permissions.includes(Permission.ExternalAccountsManage));
  }, [user]);

  useEffect(() => {
    if (!externalAccountId || !user) return;
    if (user.userType === UserType.Internal) {
      loadAccount();
    } else {
      loadAccountData();
    }
  }, [externalAccountId, user, canManageAccount]);

  // DataGrid column methods
  const getUserGridColumns = (): DataGridColDef[] => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        minWidth: 150,
        flex: 0.5,
        filterable: true,
        sortable: true,
        renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IExternalAccountUser>) {
          return (
            <div>
              {params.row.firstName} {params.row.lastName}
            </div>
          );
        },
      },
      {
        field: 'userGroupId',
        type: 'string',
        headerName: 'Group',
        minWidth: 125,
        flex: 0.25,
        filterable: true,
        sortable: true,
        renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IExternalAccountUser>) {
          if (!userGroups) return '';
          const group = userGroups.find((g) => g.userGroupId === params.value);
          return group?.name ?? <></>;
        },
      },
      {
        field: 'emailAddress',
        headerName: 'Email Address',
        type: 'string',
        minWidth: 200,
        flex: 0.5,
        filterable: true,
        sortable: true,
      },
      {
        field: 'phone',
        headerName: 'Phone Number',
        type: 'string',
        width: 150,
        filterable: true,
        sortable: true,
        renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IExternalAccountUser>) {
          return (
            <div>
              {formatPhoneNumber(params.row.phoneNumber)} {params.row.phoneNumberExt}
            </div>
          );
        },
      },
      {
        field: 'delete',
        type: 'string',
        headerName: '',
        width: 35,
        renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IExternalAccountUser>) {
          const canEditUser = userGroups?.find((q) => q.userGroupId === params.row.userGroupId)?.isAuthorized ?? false;
          if (!canEditUser) return <></>;

          return (
            <DeleteButton
              id={`deleteUser_${params.value}`}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleUserDeletePrompt(params.row);
              }}
            />
          );
        },
      },
    ];
  };

  const getCustomerGridColumns = (): DataGridColDef[] => {
    return [
      {
        field: 'customerId',
        headerName: 'Customer ID',
        type: 'number',
        width: 100,
        filterable: true,
        sortable: true,
      },
      {
        field: 'customerName',
        headerName: 'Customer Name',
        type: 'string',
        flex: 1,
        filterable: true,
        sortable: true,
      },
      {
        field: 'delete',
        type: 'string',
        headerName: '',
        width: 35,
        renderBodyCellContent(params: DataGridRenderBodyCellParams<number, IExternalAccountCustomer>) {
          return <DeleteButton id={`deleteCustomer_${params.value}`} onClick={() => handleCustomerDeletePrompt(params.row)} />;
        },
      },
    ];
  };

  const handleUserFilterChange = (
    filters: DataGridFilterField[],
    getFilterValue: <T>(filters: DataGridFilterField[], field: string) => T | undefined
  ) => {
    const name = getFilterValue<string>(filters, 'name');
    const groupName = getFilterValue<string>(filters, 'groupName');
    const emailAddress = getFilterValue<string>(filters, 'emailAddress');
    const phoneNumber = getFilterValue<string>(filters, 'phoneNumber');

    setUserSearchQuery({
      name,
      groupName,
      emailAddress,
      phoneNumber,
    });
  };

  return (
    <>
      <PageHeader>
        <IconButton id={'BackToDashboard'} icon={<ArrowBackIcon />} onClick={handleBackToAdmin} />
      </PageHeader>
      <PageBody>
        <Box sx={styles.pageBody}>
          {!accountLoading && canManageAccount && (
            <Box sx={[styles.row, styles.rowSpacing]}>
              <Box sx={[styles.cell, styles.leftColumnFlexGrow]}>
                <Box sx={styles.heading}>Account Name</Box>
                <Box sx={styles.accountNameLabel}>
                  {account?.name}
                  {account && <IconButton id={'EditAccountName'} icon={<EditIcon />} onClick={handleEditAccountName} />}
                </Box>
              </Box>
              <Box sx={[styles.cell]}>
                <Box sx={styles.heading}>Find Customers</Box>
                <Box sx={styles.findCustomers.autoComplete}>
                  <AutoComplete
                    id={'customersAutoComplete'}
                    label={'Bill to Account #'}
                    optionValueAsInputText
                    initialValue={selectedCustomerToAdd}
                    queryOptionsAsync={searchCustomers}
                    valueExtractor={(option: ICustomer) => option.customerId}
                    textExtractor={(option: ICustomer) => option.name}
                    sx={styles.findCustomers.autoCompleteInput}
                    onSelected={(option?: ICustomer) => setSelectedCustomerToAdd(option)}
                  />
                  {addCustomerLoading && (
                    <Box sx={[styles.findCustomers.addButton, styles.findCustomers.addButtonLoading]}>
                      <Spinner id={'AddCustomerSpinner'} size={20} />
                    </Box>
                  )}
                  {!addCustomerLoading && (
                    <Box sx={[styles.findCustomers.addButton, styles.findCustomers.addButtonHover]}>
                      <IconButton
                        onClick={handleAddCustomer}
                        classes={{
                          root: css({
                            '&:hover, &:disabled': {
                              backgroundColor: 'unset !important',
                            },
                          }),
                        }}
                        icon={<IoMdAdd />}
                        id={'addCustomer'}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={[styles.row, styles.rowSpacing]}>
            <Box sx={[styles.cell, styles.leftColumnFlexGrow]}>
              <Box sx={[styles.row]}>
                <Box sx={[styles.cell, styles.heading]}>Users</Box>
                <Button color={defaultColors.blue} variant="rounded-corners" id={'newUser'} text="+ New User" onClick={handleAddUserPrompt} />
              </Box>
              <DataGrid
                id={'accounts-users-data-grid'}
                rows={accountUsers}
                getRowId={(a: IExternalAccountUser) => a.userId}
                columns={getUserGridColumns()}
                bodyRowHeight={52}
                headerRowHeight={56}
                isLoading={usersLoading}
                showInputValidationErrors={true}
                onRowClick={(row: DataGridRowModel) => {
                  handleUserSelected(row as IExternalAccountUser);
                }}
                onFilterChange={handleUserFilterChange}
                onColumnSortChange={handleUserSortChange}
              />
            </Box>
            {canManageAccount && (
              <Box sx={[styles.cell]}>
                <Box sx={[styles.row]}>
                  <Box sx={[styles.cell, styles.heading]}>Assigned Customers</Box>
                </Box>
                <DataGrid
                  id={'accounts-customers-data-grid'}
                  rows={externalAccountCustomers}
                  getRowId={(a: IExternalAccountCustomer) => a.customerId}
                  columns={getCustomerGridColumns()}
                  bodyRowHeight={52}
                  headerRowHeight={56}
                  isLoading={customersLoading}
                  showInputValidationErrors={true}
                  onFilterChange={setDataGridFilterCustomers}
                  onColumnSortChange={handleCustomerGridSortChange}
                />
              </Box>
            )}
          </Box>
          {canDeleteAccount && (
            <Box sx={styles.row}>
              <Button
                id={'DeleteAccount'}
                variant={'rounded-corners'}
                color={defaultColors.red}
                text={'Delete Account'}
                classes={{ root: css({ width: '150px', justifyContent: 'center' }) }}
                onClick={handleDeleteAccountPrompt}
              />
            </Box>
          )}
          {showEditAccountModal && account && (
            <EditExternalAccountModal
              onAccept={handleAccountUpdate}
              onCancel={handleEditAccountClose}
              initialAccount={account}
              saving={editAccountLoading}
            />
          )}
          {externalAccountId && showEditExternalAccountUserModal && externalAccountUserToEdit && userGroups && (
            <EditExternalAccountUserModal
              externalAccountId={Number(externalAccountId)}
              initialUser={externalAccountUserToEdit}
              userGroups={userGroups}
              onAccept={handleUserUpdate}
              onCancel={handleUserUpdateCancel}
            />
          )}
          {showCustomerDeleteModal && customerToDelete && (
            <ConfirmationDialog
              title={'Delete Customer'}
              message={`Are you sure you want to delete ${customerToDelete.customerName}?`}
              open={showCustomerDeleteModal}
              onClose={handleCustomerDeleteCancel}
              onAccept={handleCustomerDeleteConfirm}
              onCancel={handleCustomerDeleteCancel}
              acceptLabel="Yes"
              cancelLabel="No"
              acceptLoading={customerDeleting}
            ></ConfirmationDialog>
          )}
          {showUserDeleteModal && userToDelete && (
            <ConfirmationDialog
              title={'Delete User'}
              message={`Are you sure you want to delete ${userToDelete.emailAddress}?`}
              open={showUserDeleteModal}
              onClose={handleUserDeleteCancel}
              onAccept={handleUserDeleteConfirm}
              onCancel={handleUserDeleteCancel}
              acceptLabel="Yes"
              cancelLabel="No"
              acceptLoading={userDeleting}
            ></ConfirmationDialog>
          )}
          {showAccountDeleteModal && account && (
            <ConfirmationDialog
              title={'Delete Account'}
              message={`Are you sure you want to delete ${account.name}?`}
              open={showAccountDeleteModal}
              onClose={handleAccountDeleteCancel}
              onAccept={handleAccountDeleteConfirm}
              onCancel={handleAccountDeleteCancel}
              acceptLabel="Yes"
              cancelLabel="No"
            ></ConfirmationDialog>
          )}
        </Box>
      </PageBody>
    </>
  );
}
