import { memo, useEffect, useState } from 'react';
import PricingModal, { IPricingModalState } from './PricingModal';
import { getRedButtonStyleProps, getWhiteButtonStyleProps } from '../../../../../styles/themes';
import { IContract, IPricing } from '../../../../../models';
import dayjs from 'dayjs';
import PricingGridContainer from './PricingGridContainer';
import DbgDialog from '../../../../components/dialogs/DbgDialog';
import { DialogSharedStyles } from '../../styles/DialogSharedStyles';
import { v4 } from 'uuid';
import { Updater } from 'use-immer';
import { ContractPageEvents, INewPricingFromItemTermState } from '../../ContractPage';
import { eventEmitterService } from '../../../../../services';
import constants from '../../../../../models/consts';
import { PricingGridPricingItem } from './utilities/PricingUtilities';
import { usePricingGridApi } from '@dierbergs-markets/react-feature-library';
import { usePricingGridApiProps } from './hooks/usePricingGridApiProps';

interface IProps {
  contract: IContract;
  setContract: Updater<IContract | undefined>;
  canModify: boolean;
}
const PricingSection = memo((props: IProps) => {
  const { contract, setContract } = props;

  //STATE
  const [pricingModalState, setPricingModalState] = useState<Partial<IPricingModalState> | undefined>(undefined);
  const [showDeletePricingConfirmation, setShowDeletePricingConfirmation] = useState<boolean>(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);

  //HOOKS
  const pricingGridApiProps = usePricingGridApiProps(contract);
  const { regularPricingStoreAreas } = usePricingGridApi(pricingGridApiProps);

  // CSS
  const { styles: dlgStyles } = DialogSharedStyles;

  //USE EFFECTS

  useEffect(() => {
    eventEmitterService.on(ContractPageEvents.ADD_NEW_PRICING, handleAddInitialPricingClick);
    eventEmitterService.on(ContractPageEvents.ADD_NEW_PRICING_FROM_ITEM_TERM, handleAddNewPricingFromItemTerm);
    return () => {
      eventEmitterService.off(ContractPageEvents.ADD_NEW_PRICING);
      eventEmitterService.off(ContractPageEvents.ADD_NEW_PRICING_FROM_ITEM_TERM);
    };
  }, []);

  //FUNCTIONS - PRICING GRID

  function handlePricingGridRowUpdate(row: PricingGridPricingItem) {
    setContract((draftContract) => {
      updatePricingItems(draftContract, [row]);
    });
  }

  function handlePricingGridMultiRowUpdate(rows: PricingGridPricingItem[]) {
    setContract((draftContract) => {
      updatePricingItems(draftContract, rows);
    });
  }

  function updatePricingItems(draftContract: IContract | undefined, rows: PricingGridPricingItem[]) {
    if (!draftContract) return;

    rows.forEach((row) => {
      if (!row.prices) return;

      row.prices.forEach((price, priceIndex) => {
        if (!price) return;

        price.forEach((priceAreaPrice, priceAreaIndex) => {
          if (!draftContract.pricings) return;

          const priceAreaItemIndex = draftContract.pricings[priceIndex].areas[priceAreaIndex].items.findIndex((i) => i.sku === row.item.sku);

          if (priceAreaItemIndex === -1) {
            draftContract.pricings[priceIndex].areas[priceAreaIndex].items.push({
              sku: row.item.sku,
              price: priceAreaPrice?.price ?? 0,
              multiple: priceAreaPrice?.multiple ?? 0,
            });
          } else {
            draftContract.pricings[priceIndex].areas[priceAreaIndex].items[priceAreaItemIndex].price = priceAreaPrice?.price ?? 0;
            draftContract.pricings[priceIndex].areas[priceAreaIndex].items[priceAreaItemIndex].multiple = priceAreaPrice?.multiple ?? 0;
          }
        });
      });
    });
  }

  //FUNCTIONS - PRICING MODAL

  function onPricingModalCancel() {
    setPricingModalOpen(false);
    setPricingModalState(undefined);
  }

  function onPricingModalSave(savedPricingState: IPricingModalState) {
    if (!savedPricingState.priceTypeId || !contract) return;

    if (!savedPricingState.pricingId && !savedPricingState.uniqueId) throw new Error('pricingId or unqiueId must be set.');

    setContract((draftContract) => {
      if (!draftContract || !savedPricingState.dateRange[0] || !savedPricingState.dateRange[1]) return;

      if (!draftContract.pricings) draftContract.pricings = [];

      const pricingIndex = draftContract.pricings.findIndex((p) =>
        savedPricingState.pricingId ? p.pricingId === savedPricingState.pricingId : p.uniqueId === savedPricingState.uniqueId
      );

      if (pricingIndex === -1) {
        draftContract.pricings.push({
          uniqueId: savedPricingState.uniqueId ?? v4(),
          priceTypeId: savedPricingState.priceTypeId,
          startDate: savedPricingState.dateRange[0].toDate(),
          endDate: savedPricingState.dateRange[1].toDate(),
          comments: savedPricingState.comments,
          adSiteId: savedPricingState.adSiteId,
          itemTerms: savedPricingState.itemTerms,
          orderSurveyItemSkus: [],
          areas: (regularPricingStoreAreas ?? []).map((pricingArea) => ({
            items: contract.terms.contractItems.map((ci) => ({ sku: ci.sku, multiple: 1, price: 0 })),
            stores: pricingArea,
          })),
        });
      } else {
        draftContract.pricings[pricingIndex].uniqueId = savedPricingState.uniqueId ?? v4();
        draftContract.pricings[pricingIndex].startDate = savedPricingState.dateRange[0].toDate();
        draftContract.pricings[pricingIndex].endDate = savedPricingState.dateRange[1].toDate();
        draftContract.pricings[pricingIndex].endDate = savedPricingState.dateRange[1].toDate();
        draftContract.pricings[pricingIndex].priceTypeId = savedPricingState.priceTypeId;
        draftContract.pricings[pricingIndex].comments = savedPricingState.comments;
        draftContract.pricings[pricingIndex].adSiteId = savedPricingState.adSiteId;
        draftContract.pricings[pricingIndex].itemTerms = savedPricingState.itemTerms;
      }
    });

    setPricingModalOpen(false);
    setPricingModalState(undefined);
  }

  function handlePricingDelete(pricingId?: number, uniqueId?: string) {
    if (!pricingId && !uniqueId) throw new Error(`Unable to delete ${constants.terms.displayName}.`);

    setContract((draftContract) => {
      if (!draftContract || !draftContract.pricings) return;

      const pricingIndex = draftContract.pricings.findIndex((p) =>
        pricingId && pricingId > 0 ? p.pricingId === pricingId : uniqueId && p.uniqueId === uniqueId
      );

      if (pricingIndex !== -1) draftContract.pricings.splice(pricingIndex, 1);
    });
  }

  function onPricingModalDelete() {
    setShowDeletePricingConfirmation(false);
    pricingModalState && handlePricingDelete(pricingModalState.pricingId, pricingModalState.uniqueId);
    setPricingModalState(undefined);
  }

  function handleAddNewPricingFromItemTerm(payload: INewPricingFromItemTermState) {
    setPricingModalState({ itemTerms: [], dateRange: payload.dateRange, initialItemTermUniqueId: payload.itemTermUniqueId });
    setPricingModalOpen(true);
  }

  function handleAddInitialPricingClick() {
    if (!contract) return;

    setPricingModalState({ itemTerms: [] });
    setPricingModalOpen(true);
  }

  function handlePricingUpdate(pricingIndex: number, pricing: IPricing) {
    setContract((draftContract) => {
      if (!draftContract || !draftContract.pricings) return;
      draftContract.pricings[pricingIndex] = pricing;
    });
  }

  function handlePricingGridPricingEdit(pricing: IPricing) {
    setPricingModalState({
      pricingId: pricing.pricingId,
      comments: pricing.comments,
      adSiteId: pricing.adSiteId,
      dateRange: [dayjs(pricing.startDate), dayjs(pricing.endDate)],
      itemTerms: pricing.itemTerms.filter((it) => {
        //if same contract, and term doesn't exist (deleted during current session), don't include the term
        if (contract && contract.contractId === it.contract.contractId) {
          const termExists = contract.terms.contractTermsForItem.some((cti) => cti.uniqueId === it.itemTermUniqueId);
          return termExists;
        }
        return true;
      }),
      priceTypeId: pricing.priceTypeId,
      uniqueId: pricing.uniqueId,
    });
    setPricingModalOpen(true);
  }
  return (
    <>
      <PricingGridContainer
        contract={contract}
        canModify={props.canModify}
        contractLoading={false}
        onRowUpdate={handlePricingGridRowUpdate}
        onMultiRowUpdate={handlePricingGridMultiRowUpdate}
        onPricingEdit={handlePricingGridPricingEdit}
        onPricingUpdate={handlePricingUpdate}
      />
      {pricingModalOpen && pricingModalState && (
        <PricingModal
          id={'PricingModal'}
          contract={contract}
          initialState={pricingModalState}
          open={pricingModalOpen}
          onDelete={() => props.canModify && setShowDeletePricingConfirmation(true)}
          onSave={onPricingModalSave}
          onCancel={onPricingModalCancel}
        />
      )}
      <DbgDialog
        id="deletePricingConfirmationDialog"
        open={props.canModify && showDeletePricingConfirmation}
        cancelText="Cancel"
        sx={dlgStyles.dialogContent}
        confirmButtonTheme={getRedButtonStyleProps()}
        cancelButtonTheme={{ ...getWhiteButtonStyleProps(), width: '150px !important' }}
        confirmText="Yes, Delete"
        onConfirm={onPricingModalDelete}
        onCancel={() => setShowDeletePricingConfirmation(false)}
        title="Are you sure you want to delete this pricing?"
      ></DbgDialog>
    </>
  );
});

export default PricingSection;
