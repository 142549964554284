import { CostImplicationType } from '@dierbergs-markets/react-feature-library';
import { IReferenceDataState } from '../../../../../../contexts/ApplicationContext';
import { IContract, IContractItem, IItem } from '../../../../../../models';
import {
  computeCaseCost,
  computeCaseReductions,
  computeUnitCost,
  createCostImplications,
  getCaseRatio,
  IPricingCostImplication,
} from '../utilities/PricingUtilities';
import { getMax, sumIfKnown } from '../../../../../../utilities/ObjectUtilities';

export interface IPricingSource {
  shipper: IShipperCostWorksheet | null;
  caseListCost?: number;
  quantity: number;
  caseCost: number | undefined;
  caseReductions: number;
  unitCost: number | undefined;
  unitReductions: number;
}

export interface ISourcedPricingItem {
  sku: number;
  item: IItem;
  suggestedRetailPrice?: number;
  suggestedRetailMultiple?: number;

  source?: IPricingSource; //the selected, most-expensive source
  sources: IPricingSource[];
}

export interface IShipperCostWorksheet {
  item: IItem;
  contents: IShipperItemCostWorksheet[];
  caseListCost?: number;
}

interface IShipperItemCostWorksheet {
  item?: IItem;
  quantity?: number;
  regularUnitCost?: number;
  regularCaseListCost?: number;
  ratio?: number;
  caseListCost?: number;
  unitListCost?: number;
}

export class SourcedPricingItemsBuilder {
  constructor() {
    this.items = {};
  }

  items: Record<number, Omit<ISourcedPricingItem, 'source'>>;

  getItems() {
    return Object.values(this.items);
  }

  addItemSource(contractItem: IContractItem, contract: IContract, referenceData: IReferenceDataState) {
    if (!contractItem.item) return;

    if (!contractItem.childData || contractItem.childData.length === 0) {
      //open stock
      const costImplications = createCostImplications(contractItem.sku, contractItem.sku, 1, contract, referenceData);
      const item = this.addSource(
        contractItem.sku,
        contractItem.item,
        this.createPricingSource(null, costImplications, contractItem.item.quantityPerPack, contractItem.caseListCost)
      );
      item.suggestedRetailPrice = contractItem.suggestedRetailPrice;
      item.suggestedRetailMultiple = contractItem.suggestedRetailMultiple;
    } else {
      //shipper
      const shipperWorksheet = computeShipperCostWorksheet(contractItem);
      if (!shipperWorksheet) return;
      for (const content of contractItem.childData) {
        if (!content.item) continue;
        const contentCost = shipperWorksheet.contents.find((c) => c.item?.sku === content.sku);
        const caseRatio = getCaseRatio(shipperWorksheet, contentCost?.caseListCost);
        const costImplications = createCostImplications(shipperWorksheet.item.sku, content.sku, caseRatio, contract, referenceData);
        this.addSource(
          content.sku,
          content.item,
          this.createPricingSource(shipperWorksheet, costImplications, content.item.quantityPerPack, contentCost?.caseListCost)
        );
      }
    }
  }

  private addSource(sku: number, item: IItem, source: IPricingSource): ISourcedPricingItem {
    const pricingItem = this.getOrCreate(sku, item);
    pricingItem.sources.push(source);
    return pricingItem;
  }

  private getOrCreate(sku: number, item: IItem): Omit<ISourcedPricingItem, 'source'> {
    const existing = this.items[sku];
    if (existing) return existing;

    const newItem = {
      sku: sku,
      item: item,
      sources: [],
    } as Omit<ISourcedPricingItem, 'source'>;
    this.items[sku] = newItem;
    return newItem;
  }

  private createPricingSource(
    shipperWorksheet: IShipperCostWorksheet | null,
    costImplications: IPricingCostImplication[],
    quantity: number,
    caseListCost: number | undefined
  ): IPricingSource {
    return {
      shipper: shipperWorksheet,
      quantity: quantity,
      caseListCost: caseListCost,
      caseCost: computeCaseCost(caseListCost, costImplications),
      caseReductions: computeCaseReductions(costImplications),
      unitCost: computeUnitCost(caseListCost, quantity, costImplications),
      unitReductions: computeUnitOnlyReductions(quantity, costImplications),
    };
  }
}

function computeUnitOnlyReductions(
  quantityPerPack: number | undefined,
  costImplications: (IPricingCostImplication | undefined)[] | undefined //selected only
): number {
  if (!costImplications || !quantityPerPack) return 0;

  return costImplications.reduce((subtotal, ci) => {
    if (!ci) return subtotal;
    if (ci.type === CostImplicationType.SINGLEUNIT) {
      return subtotal + ci.amount;
    }

    return subtotal;
  }, 0);
}

function computeShipperCostWorksheet(contractItem: IContractItem): IShipperCostWorksheet | null {
  if (!contractItem.item) return null;

  const contentRegularCosts =
    contractItem.childData?.map((content) => {
      const quantity = content.item?.quantityPerPack;
      //choose the highest regular cost
      const regularUnitCost = content.item ? getMax(content.item.regularPrices.map((r) => r.price / r.multiple)) : undefined;
      const regularCaseListCost = quantity && regularUnitCost ? quantity * regularUnitCost : undefined;
      return {
        item: content.item,
        quantity: quantity,
        regularUnitCost: regularUnitCost,
        regularCaseListCost: regularCaseListCost,
      } as Omit<Omit<Omit<IShipperItemCostWorksheet, 'caseListCost'>, 'unitListCost'>, 'ratio'>;
    }) ?? [];
  if (contentRegularCosts.length === 0) return null;

  const totalRegularCaseListCost = sumIfKnown(contentRegularCosts, (rc) => rc.regularCaseListCost);

  const contentCosts = contentRegularCosts.map((rc) => {
    const ratio = !rc.regularCaseListCost || !totalRegularCaseListCost ? undefined : rc.regularCaseListCost / totalRegularCaseListCost;
    const caseListCost = !ratio || !contractItem.caseListCost ? undefined : ratio * contractItem.caseListCost;
    const unitListCost = !caseListCost || !rc.quantity ? undefined : caseListCost / rc.quantity;
    return { ...rc, ratio: ratio, caseListCost: caseListCost, unitListCost: unitListCost } as IShipperItemCostWorksheet;
  });

  return {
    item: contractItem.item,
    caseListCost: contractItem.caseListCost,
    contents: contentCosts,
  } as IShipperCostWorksheet;
}
