import { v4 } from 'uuid';
import { IPricing } from '../../models';
import { fetchGetJson, HttpErrorResponse } from '../contractHubApi';

export const contractPricingService = {
  getPricing,
};

async function getPricing(contractId: number): Promise<IPricing[] | HttpErrorResponse> {
  const response = await fetchGetJson<IPricing[]>({ endpoint: `contract/${contractId}/pricing` });
  if (response instanceof HttpErrorResponse) return response;

  return response.map((p) => ({
    pricingId: p.pricingId,
    uniqueId: v4(), //use stored value?
    priceTypeId: p.priceTypeId,
    adSiteId: p.adSiteId,
    startDate: p.startDate,
    endDate: p.endDate,
    comments: p.comments,
    itemTerms: p.itemTerms,
    orderSurveyItemSkus: p.orderSurveyItemSkus,
    areas: p.areas,
  }));
}
