import { useMemo } from 'react';
import { PricingGridApiConfigProps } from '@dierbergs-markets/react-feature-library';
import { IContract } from '../../../../../../models';
import { useApplicationContextState } from '../../../../../../contexts/ApplicationContext';
import { getContractPricingItems, PricingGridPricingItem } from '../utilities/PricingUtilities';
import { getDistinctStores } from '../../../../../../utilities/ContractUtilities';

/**
 * A hook that provides consistent configuration props for the PricingGrid API
 * This centralizes the creation of configuration props to avoid duplication
 *
 * @param contract - The contract containing pricing data
 * @param additionalConfig - Any additional configuration options
 * @returns PricingGridApiConfigProps object used by PricingGrid and related components
 */
export function usePricingGridApiProps(
  contract?: IContract,
  additionalConfig?: {
    pricingColumnConfigurations?: any[];
  }
): PricingGridApiConfigProps<PricingGridPricingItem> {
  const { referenceData } = useApplicationContextState();

  return useMemo(() => {
    if (!referenceData || !contract) {
      return {
        additionalColumns: [],
        rows: [],
        pricingColumnConfigurations: additionalConfig?.pricingColumnConfigurations || [],
      };
    }

    return {
      additionalColumns: [],
      rows: getContractPricingItems(contract, referenceData),
      pricingColumnConfigurations: additionalConfig?.pricingColumnConfigurations || [],
      storesFilter: getDistinctStores(contract.terms.stores, referenceData).map((store) => store.id),
    };
  }, [contract, referenceData, additionalConfig?.pricingColumnConfigurations]);
}
