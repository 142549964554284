import { useEffect, useState } from 'react';
import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { useParams } from 'react-router-dom';
import { IContract, IContractItem, IItem } from '../../../models';
import { contractTermService } from '../../../services';
import { HttpErrorResponse } from '../../../services/contractHubApi';
import { defaultColors } from '../../../styles/variables';
import { tss } from 'tss-react';

export default function ContractPrintPage() {
  const { referenceData } = useApplicationContextState();
  const { id } = useParams();

  const [contract, setContract] = useState<IContract | undefined>(undefined);
  const { classes, cx } = styles();

  useEffect(() => {
    (async () => {
      if (id) {
        const response = await contractTermService.getContract(+id);
        if (response instanceof HttpErrorResponse) {
          console.error(response);
        } else {
          setContract(response);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (contract) window.print();
  }, [contract]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const getEndDate = () => {
    if (contract && contract.terms.contractTermsForItem.length > 0) {
      const endDates: number[] = contract.terms.contractTermsForItem.map((q) => q.endDate?.getTime()).filter((q) => q != undefined) as number[];
      return new Date(Math.max(...endDates));
    }

    return contract?.terms.startDate;
  };

  const getPriceAssociationCode = (item?: IItem) => {
    if (item && item.suppliers && item.suppliers.length > 0) return item.suppliers[0].priceAssociationCode;
  };

  const renderStores = () => {
    if (!contract) return <></>;
    let selection = 'All Stores';

    if (contract.terms.stores.storeIds.length === 1) {
      selection = referenceData?.stores.byId[contract.terms.stores.storeIds[0]].displayName || '';
    } else if (contract.terms.stores.storeIds.length > 1) {
      selection = 'Multiple Stores';
    } else if (contract.terms.stores.storeGroupIds.length > 0) {
      selection = referenceData?.storeGroups.byId[contract.terms.stores.storeGroupIds[0]].displayName ?? '';
    }
    return <>{selection}</>;
  };

  const renderUpcRow = (contractItem: IContractItem, index: number, parentItem?: IContractItem) => {
    const isParentRow = contractItem.childData && contractItem.childData.length > 0;
    return (
      <>
        <tr key={contractItem.sku} className={cx(classes.tr, isParentRow && classes.parentRow, parentItem && classes.childRow)}>
          <td className={classes.td}>{contractItem.item?.upc}</td>
          <td className={classes.td}>{contractItem.item?.orderCode}</td>
          <td className={classes.td}>{contractItem.item?.department}</td>
          <td className={classes.td}>{getPriceAssociationCode(contractItem.item)}</td>
          <td className={classes.td}>{contractItem.item?.quantityPerPack}</td>
          <td className={classes.td}>{contractItem.item?.size}</td>
          <td className={classes.td}>{contractItem.item?.description}</td>
          <td className={classes.td}>{formatAsMoney(contractItem.caseListCost)}</td>
          {parentItem && parentItem.amounts.map(() => <td className={classes.td}></td>)}
          {!parentItem &&
            contractItem.amounts.map((a) => {
              return <td className={classes.td}>{formatAsMoney(a)}</td>;
            })}
          <td className={classes.td}>{contractItem.suggestedRetailMultiple}</td>
          <td className={classes.td}>{formatAsMoney(contractItem.suggestedRetailPrice)}</td>
        </tr>
        {contractItem.childData && contractItem.childData.map((childItem, cIdx) => renderUpcRow(childItem, index + cIdx, contractItem))}
      </>
    );
  };

  const formatAsMoney = (val) => {
    if (!val) return '';
    return `$${val.toFixed(2)}`;
  };

  if (!contract || !referenceData) return <></>;

  const hasDigitalRewards = contract.terms.contractTermsForLumpSum.filter((q) => q.digitalRewards).length > 0;

  return (
    <>
      <table>
        <thead>
          <tr>
            <td>
              <div className={classes.headerTop}>
                <div>
                  <h1 className={classes.title}>Contract #: {contract.terms.vendorContractNumber}</h1>
                  <p className={classes.subtitle}>
                    <strong>Internal Contract #:</strong> {contract.contractId}
                  </p>
                </div>
              </div>
              <div className={classes.headerGrid}>
                <div>
                  <p className={classes.text}>
                    <strong>Bill To Account:</strong> {contract.terms.customer?.customerId} / {contract.terms.customer?.name}
                  </p>
                </div>
                <div>
                  <p className={classes.text}>
                    <strong>Manufacturer:</strong> {contract.terms.manufacturer?.name}
                  </p>
                </div>
                <div>
                  <p className={classes.text}>
                    <strong>Supplier:</strong> {contract.terms.supplier?.name}
                  </p>
                </div>
                <div>
                  <p className={classes.text}>
                    <strong>Category Manager:</strong> {contract.terms.categoryManager?.name}
                  </p>
                </div>
                <div>
                  <p className={classes.text}>
                    <strong>Contract Period:</strong> {formatDate(contract.terms.startDate)} - {formatDate(getEndDate())}
                  </p>
                </div>
                <div>
                  <p className={classes.text}>
                    <strong>Stores:</strong> {renderStores()}
                  </p>
                </div>
                <div>
                  <p className={classes.text}>
                    <strong>Comments:</strong> {contract.terms.comments}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className={classes.tableContainer}>
                <table className={classes.table}>
                  <thead>
                    <tr className={classes.headerRow}>
                      <th className={classes.th} style={{ width: '90px' }}>
                        UPC
                      </th>
                      <th className={classes.th} style={{ width: '75px' }}>
                        Order Code
                      </th>
                      <th className={classes.th} style={{ width: '75px' }}>
                        Department
                      </th>
                      <th className={classes.th} style={{ width: '90px' }}>
                        Price Association
                      </th>
                      <th className={classes.th} style={{ width: '30px' }}>
                        Pack
                      </th>
                      <th className={classes.th} style={{ width: '30px' }}>
                        Size
                      </th>
                      <th className={classes.th} style={{ width: '20%' }}>
                        Description
                      </th>
                      <th className={classes.th} style={{ width: '75px' }}>
                        Case List Cost
                      </th>
                      {contract.terms.contractTermsForItem.map((q) => {
                        const termType = referenceData.termTypes.byId[q.termTypeId!];
                        return (
                          <th className={classes.th} style={{ width: '90px' }}>
                            {termType.name} ({formatDate(q.startDate)} - {formatDate(q.endDate)})
                          </th>
                        );
                      })}
                      <th className={classes.th} style={{ width: '50px' }}>
                        Multiple
                      </th>
                      <th className={classes.th} style={{ width: '50px' }}>
                        SRP
                      </th>
                    </tr>
                  </thead>
                  <tbody>{contract.terms.contractItems.map((ci, idx) => renderUpcRow(ci, idx))}</tbody>
                </table>
              </div>
              {contract.terms.contractTermsForLumpSum.length > 0 && (
                <div className={cx(classes.tableContainer, classes.lumpSumTable)}>
                  <table className={classes.table}>
                    <thead>
                      <tr className={classes.headerRow}>
                        <th className={classes.th} style={{ width: '90px' }}>
                          Vendor Funded Program
                        </th>
                        <th className={classes.th} style={{ width: '75px' }}>
                          Effective Date
                        </th>
                        <th className={classes.th} style={{ width: '75px' }}>
                          Department
                        </th>
                        {hasDigitalRewards && (
                          <th className={classes.th} style={{ width: '90px' }}>
                            Reward Points Multiplier
                          </th>
                        )}
                        {hasDigitalRewards && (
                          <th className={classes.th} style={{ width: '90px' }}>
                            Reward Points Start/End Date
                          </th>
                        )}
                        <th className={classes.th} style={{ width: '30px' }}>
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {contract.terms.contractTermsForLumpSum.map((q, index) => {
                        const termType = referenceData.termTypes.byId[q.termTypeId!];
                        const department = referenceData.departments.byId[q.departmentId!];
                        return (
                          <tr key={index} className={classes.tr}>
                            <td className={classes.td}>{termType.name}</td>
                            <td className={classes.td}>{formatDate(q.effectiveDate)}</td>
                            <td className={classes.td}>{department.name}</td>
                            {hasDigitalRewards && !q.digitalRewards && <td colSpan={2}></td>}
                            {hasDigitalRewards && q.digitalRewards && <td className={classes.td}>{q.digitalRewards.rewardPointsMultiplier}X</td>}
                            {hasDigitalRewards && q.digitalRewards && (
                              <td className={classes.td}>
                                {formatDate(q.digitalRewards.startDate)} to {formatDate(q.digitalRewards.endDate)}
                              </td>
                            )}
                            <td className={classes.td}>{formatAsMoney(q.amount)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <style>{`
          @media print {
            @page {
              size: letter landscape;
              margin: 0.25in;
              margin-bottom: 0.5in;
            
              @bottom-right {
                content: "Page " counter(page) " of " counter(pages);
              }
            }
          }

          tr {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
          }
      `}</style>
    </>
  );
}

const styles = tss.create({
  headerTop: {
    marginBottom: '10px',
  },
  headerGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    marginBottom: '15px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '3px',
    marginTop: '0',
  },
  subtitle: {
    fontSize: '12px',
    marginBottom: '5px',
    marginTop: '0',
  },
  text: {
    fontSize: '12px',
    marginBottom: '3px',
    marginTop: '0',
  },
  tableContainer: {
    width: '100%',
  },
  lumpSumTable: {
    marginTop: '25px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse' as any,
    fontSize: '10px',
    tableLayout: 'fixed' as any,
  },
  headerRow: {
    backgroundColor: defaultColors.altGrey,
  },
  th: {
    border: `1px solid ${defaultColors.grey}`,
    padding: '4px',
    backgroundColor: defaultColors.altGrey,
    fontWeight: 'bold',
    fontSize: '10px',
    overflow: 'hidden',
    whiteSpace: 'normal',
  },
  td: {
    border: `1px solid ${defaultColors.grey}`,
    padding: '4px',
    overflow: 'hidden',
  },
  tr: {
    ':nth-child(odd)': {
      backgroundColor: defaultColors.white,
    },
    ':nth-child(even)': {
      backgroundColor: defaultColors.lightGrey,
    },
  },
  parentRow: {
    fontWeight: 'bold',
  },
  childRow: {
    backgroundColor: `${defaultColors.blueGrey} !important`,
  },
});
