/**
 * Performs a shallow comparison of two objects.
 * Returns true if all top-level keys match strictly, false otherwise.
 *
 * @param objA The first object to compare.
 * @param objB The second object to compare.
 * @returns {boolean} True if both objects are shallowly equal, otherwise false.
 */
export function shallowEquals<T extends object>(objA: T, objB: T): boolean {
  // Same reference short-circuit
  if (objA === objB) return true;

  // Handle null/undefined mismatch
  if (!objA || !objB) return false;

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  // If they have a different number of keys, they can't be equal
  if (keysA.length !== keysB.length) return false;

  // Check each key's value strictly
  for (const key of keysA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
}

export function addIfKnown(a: number | undefined, b: number | undefined): number | undefined {
  return a === undefined || b === undefined ? undefined : a + b;
}

export function sumIfKnown<T>(array: T[], selector: (item: T) => number | undefined): number | undefined {
  return array.reduce((accumulator, item) => addIfKnown(accumulator, selector(item)), 0 as number | undefined);
}

export function findMax<T>(items: T[], selector: (item: T) => number | undefined): T | undefined {
  const maximumItemAndValue = items.reduce((max: { item: T; value: number } | undefined, currentItem: T) => {
    const currentValue = selector(currentItem);
    return currentValue !== undefined && (max === undefined || currentValue > max.value) ? { item: currentItem, value: currentValue } : max;
  }, undefined);
  return maximumItemAndValue?.item;
}

export function getMax(items: number[]): number | undefined {
  const maximumValue = items.reduce((max: number | undefined, current: number | undefined) => {
    return current !== undefined && (max === undefined || current > max) ? current : max;
  }, undefined);
  return maximumValue;
}
export function distinctByKey<TItem, TKey>(array: TItem[], id: (item: TItem) => TKey) {
  return array.reduce((acc: TItem[], item: TItem) => {
    if (!acc.find((existing) => id(existing) === id(item))) acc.push(item);
    return acc;
  }, []);
}
