import { IContract, IContractTerms, IItem, IPricing, IPricingItemTerm, IPricingItemTermItem } from '../../../../../../models';
import * as PG from '@dierbergs-markets/react-feature-library';
import { IReferenceDataState } from '../../../../../../contexts/ApplicationContext';
import { IPricingSource, IShipperCostWorksheet, ISourcedPricingItem, SourcedPricingItemsBuilder } from '../classes/SourcedPricingItemsBuilder';
import { distinctByKey, findMax } from '../../../../../../utilities/ObjectUtilities';
import { CostImplicationType } from '@dierbergs-markets/react-feature-library';

// Interfaces
export interface PricingGridPricingItem extends PG.IPricingItem {
  suggestedRetailPrice?: number;
  suggestedRetailMultiple?: number;
  source?: IPricingSource;
  sources: IPricingSource[];
}

export interface IPricingCostImplication extends PG.ICostImplication {
  itemTerm: IPricingItemTerm;
  type: PG.CostImplicationType;
}

// Main Contract Pricing Items Functions
export function getContractPricingItems(contract?: IContract, referenceData?: IReferenceDataState): PricingGridPricingItem[] {
  if (!contract || !referenceData) return [];
  const sourcedItemsWithoutSelection = gatherContractItemsWithSources(contract, referenceData);
  const sourceItemsWithSelection = sourcedItemsWithoutSelection.map((item) => {
    return {
      ...item,
      source: findMax<IPricingSource>(item.sources, (s) => s.unitCost),
    } as ISourcedPricingItem;
  });

  return sourceItemsWithSelection.map((pricingItem) => {
    const result: PricingGridPricingItem = {
      rowType: 'Parent',
      item: createPricingGridItem(
        pricingItem.sku,
        pricingItem.item,
        contract.terms.supplier?.id,
        pricingItem.source?.caseListCost,
        pricingItem.source?.quantity
      ),
      costImplications: createCostImplicationsForPricingItem(pricingItem, contract, getPricingSourceCaseRatio(pricingItem.source), referenceData),
      prices: extractPricesBySku(pricingItem.sku, contract.pricings),
      suggestedRetailMultiple: pricingItem.suggestedRetailMultiple,
      source: pricingItem.source,
      sources: pricingItem.sources,
    };
    return result;
  });
}

function gatherContractItemsWithSources(contract: IContract, referenceData: IReferenceDataState): Omit<ISourcedPricingItem, 'source'>[] {
  const builder = new SourcedPricingItemsBuilder();
  for (const contractItem of contract.terms.contractItems) {
    builder.addItemSource(contractItem, contract, referenceData);
  }
  return builder.getItems();
}

// Helper Item Functions
function createPricingGridItem(
  sku: number,
  item: IItem | undefined,
  supplierId: number | undefined,
  caseListCost: number | undefined,
  quantity: number | undefined
): PG.IItem {
  if (!item) {
    return {
      sku: sku,
      upc: 0,
      description: 'Unknown Item',
      displaySize: '',
      regularPrices: [],
    };
  }

  return {
    packSize: quantity,
    sku: sku,
    upc: item.upc,
    description: item.description,
    displaySize: `${item.size} ${item.unitOfMeasure}`,
    priceAssociation: item.suppliers?.find((s) => s.id === supplierId)?.priceAssociationCode,
    caseCost: caseListCost,
    orderCode: item.orderCode,
    regularPrices: item.regularPrices,
  };
}

function extractPricesBySku(sku: number, pricings?: IPricing[]): (PG.IPrice | undefined)[][] {
  if (!pricings) return [];
  const result: (PG.IPrice | undefined)[][] = [];

  pricings.forEach((pricing) => {
    const pricingPrices: (PG.IPrice | undefined)[] = [];

    pricing.areas.forEach((area) => {
      const itemPrice = area.items.find((i) => i.sku === sku);
      pricingPrices.push(itemPrice ? { multiple: itemPrice.multiple, price: itemPrice.price } : { multiple: 1, price: 0 });
    });

    result.push(pricingPrices);
  });
  return result;
}

// CostImplication Creation Functions
function createCostImplicationsForPricingItem(
  pricingItem: ISourcedPricingItem,
  contract: IContract,
  caseRatio: number | undefined,
  referenceData: IReferenceDataState
): IPricingCostImplication[] {
  const itemSku = pricingItem.sku;
  const caseSku = pricingItem.source?.shipper?.item.sku ?? pricingItem.sku;
  return createCostImplications(caseSku, itemSku, caseRatio, contract, referenceData);
}

export function createCostImplications(
  caseSku: number,
  itemSku: number,
  caseRatio: number | undefined,
  contract: IContract,
  referenceData: IReferenceDataState
): IPricingCostImplication[] {
  if (!contract.pricings) return [];
  return distinctByKey(
    contract.pricings.flatMap((x) => x.itemTerms),
    (itemTerm) => itemTerm.itemTermUniqueId
  ).map((itemTerm) => createCostImplication(itemTerm, caseSku, itemSku, caseRatio, contract.terms, referenceData));
}

function createCostImplication(
  itemTerm: IPricingItemTerm,
  caseSku: number,
  itemSku: number,
  caseRatio: number | undefined,
  contractTerms: IContractTerms,
  referenceData: IReferenceDataState
): IPricingCostImplication {
  const costImplicationType = getCostImplicationType(itemTerm.termUnitOfMeasureId, referenceData);
  const sku =
    costImplicationType === CostImplicationType.CASE ? caseSku : costImplicationType === CostImplicationType.SINGLEUNIT ? itemSku : undefined;
  const ratio = costImplicationType === CostImplicationType.CASE ? caseRatio : costImplicationType === CostImplicationType.SINGLEUNIT ? 1 : undefined;

  if (!sku || !ratio) return createPricingCostImplication(costImplicationType, itemTerm, 0);

  const amount =
    getItemAmountFromContractTerms(contractTerms, itemTerm.itemTermUniqueId, sku) ?? getItemAmountFromItemTerms(itemTerm.items, sku) ?? 0;

  return createPricingCostImplication(costImplicationType, itemTerm, amount * ratio);
}

function createPricingCostImplication(type: PG.CostImplicationType, itemTerm: IPricingItemTerm, amount: number): IPricingCostImplication {
  return { type: type, uniqueId: itemTerm.itemTermUniqueId, itemTerm: itemTerm, amount: amount };
}

function createPricingCostImplicationsFromGridType(
  costImplications: (PG.ICostImplication | undefined)[] | undefined,
  itemTerms: IPricingItemTerm[],
  referenceData: IReferenceDataState
): IPricingCostImplication[] {
  if (!costImplications) return [];

  const results: IPricingCostImplication[] = [];
  for (const ci of costImplications) {
    if (!ci) continue;
    const itemTerm = itemTerms.find((it) => it.itemTermUniqueId === ci.uniqueId);
    if (!itemTerm) continue;
    const type = getCostImplicationType(itemTerm.termUnitOfMeasureId, referenceData);
    results.push({ ...ci, type: type, itemTerm: itemTerm });
  }
  return results;
}

// Amount and Item Handling Functions
function getItemAmountFromItemTerms(itemTermItems: IPricingItemTermItem[], sku: number): number | undefined {
  const item = itemTermItems.find((i) => i.sku === sku);

  if (item) return item?.amount ?? 0;
  return undefined;
}

function getItemAmountFromContractTerms(contractTerms: IContractTerms, itemTermUniqueId: string, sku: number): number | undefined {
  const localContractTermIndex = contractTerms.contractTermsForItem.findIndex((cti) => cti.uniqueId === itemTermUniqueId);

  if (localContractTermIndex !== -1) {
    const localContractItem = contractTerms.contractItems.find((ci) => ci.sku === sku);
    if (localContractItem) return localContractItem.amounts[localContractTermIndex] ?? 0;
  }
  return undefined;
}

// Cost Computation Functions
export function computeCaseCostForRow(row: PricingGridPricingItem, p: IPricing, referenceData: IReferenceDataState): number | undefined {
  if (!row.costImplications) return row.item.caseCost || undefined;

  return computeCaseCostForPricingGridCostImplications(row.item.caseCost, row.costImplications, p.itemTerms, referenceData);
}

function computeCaseCostForPricingGridCostImplications(
  itemCaseListCost: number | undefined,
  costImplications: (PG.ICostImplication | undefined)[],
  itemTerms: IPricingItemTerm[],
  referenceData: IReferenceDataState
) {
  return computeCaseCost(itemCaseListCost, createPricingCostImplicationsFromGridType(costImplications, itemTerms, referenceData));
}

export function computeCaseCost(
  itemCaseListCost: number | undefined,
  selectedCostImplications: (IPricingCostImplication | undefined)[]
): number | undefined {
  if (itemCaseListCost === undefined) return undefined;

  return itemCaseListCost - computeCaseReductions(selectedCostImplications);
}

export function computeCaseReductions(selectedCostImplications: (IPricingCostImplication | undefined)[]): number {
  return selectedCostImplications.reduce((subtotal, ci) => {
    if (!ci) return subtotal;
    if (ci.type !== CostImplicationType.CASE) return subtotal;

    return subtotal + ci.amount;
  }, 0);
}

export function computeUnitCostForRow(row: PricingGridPricingItem, p: IPricing, referenceData: IReferenceDataState): number | undefined {
  return computeUnitCostForPricingGridCostImplications(row.item, row.costImplications, p.itemTerms, referenceData);
}

function computeUnitCostForPricingGridCostImplications(
  item: PG.IItem,
  costImplications: (PG.ICostImplication | undefined)[] | undefined,
  itemTerms: IPricingItemTerm[],
  referenceData: IReferenceDataState
): number | undefined {
  return computeUnitCostForPricingGridItem(item, createPricingCostImplicationsFromGridType(costImplications, itemTerms, referenceData));
}

function computeUnitCostForPricingGridItem(
  item: PG.IItem,
  selectedCostImplications: (IPricingCostImplication | undefined)[] | undefined
): number | undefined {
  return computeUnitCost(item.caseCost, item.packSize, selectedCostImplications);
}

export function computeUnitCost(
  caseListCost: number | undefined,
  quantityPerPack: number | undefined,
  selectedCostImplications: (IPricingCostImplication | undefined)[] | undefined
): number | undefined {
  if (!caseListCost || !quantityPerPack) return undefined;
  const unadjustedUnitCost = caseListCost / quantityPerPack;
  return unadjustedUnitCost - computeUnitReductions(quantityPerPack, selectedCostImplications);
}

function computeUnitReductions(quantityPerPack: number | undefined, costImplications: (IPricingCostImplication | undefined)[] | undefined): number {
  if (!costImplications || !quantityPerPack) return 0;

  return costImplications.reduce((subtotal, ci) => {
    if (!ci) return subtotal;
    if (ci.type === CostImplicationType.CASE) {
      return subtotal + ci.amount / quantityPerPack;
    } else if (ci.type === CostImplicationType.SINGLEUNIT) {
      return subtotal + ci.amount;
    }

    return subtotal;
  }, 0);
}

// Utility Functions
export function getCostImplicationType(unitOfMeasureId: number, referenceData?: IReferenceDataState) {
  const uom = referenceData?.termTypeUnitsOfMeasure.byId[unitOfMeasureId];

  switch (uom?.name.toUpperCase()) {
    case 'CASE':
      return PG.CostImplicationType.CASE;

    default:
      return PG.CostImplicationType.SINGLEUNIT;
  }
}

function getPricingSourceCaseRatio(source?: IPricingSource) {
  if (!source) return undefined;
  return getCaseRatio(source.shipper, source.caseListCost);
}

export function getCaseRatio(shipperWorksheet: IShipperCostWorksheet | null, itemCaseListCost: number | undefined) {
  if (!shipperWorksheet) return 1;
  if (!itemCaseListCost || !shipperWorksheet.caseListCost) return undefined;
  return itemCaseListCost / shipperWorksheet.caseListCost;
}
