import { IReferenceDataState } from '../contexts/ApplicationContext';
import { IStoreSelection, IStore } from '../models';

export function getDistinctStores(storeSelections: IStoreSelection, referenceData?: IReferenceDataState): IStore[] {
  const { storeIds = [], storeGroupIds = [] } = storeSelections;

  if (!referenceData || !referenceData?.stores || !referenceData?.storeGroups) {
    return [];
  }

  // Get all store numbers based on storeGroupIds
  const storeNumbers: number[] = [];
  storeGroupIds.forEach((groupId) => {
    const group = referenceData.storeGroups.byId[groupId];
    if (group) storeNumbers.push(...group.storeNumbers);
  });

  // Add storeIds to storeNumbers
  storeIds.forEach((storeId) => {
    if (!storeNumbers.includes(storeId)) storeNumbers.push(storeId);
  });

  // Filter out duplicate storeNumbers
  const distinctStoreNumbers = Array.from(new Set(storeNumbers));

  // Retrieve store information based on storeNumbers
  const distinctStores: IStore[] = distinctStoreNumbers
    .map((storeNumber) => {
      const store = referenceData.stores.byId[storeNumber];
      return store ? { id: store.id, name: store.name, displayName: store.displayName } : null;
    })
    .filter((store) => store !== null) as IStore[]; // Filter out null entries

  return distinctStores.sort((a, b) => a.displayName.localeCompare(b.displayName));
}
