import { Route, Routes } from 'react-router-dom';
import ContractPage from '../pages/Contract/ContractPage';
import DashboardPage from '../pages/Dashboard/DashboardPage';
import NotFound from '../pages/public/NotFound';
import AuthenticatedLayout from './AuthenticatedLayout';
import Redeem from '../pages/public/Redeem';
import SignInFailure from '../pages/public/SignInFailure';
import AdminPage from '../pages/Admin/AdminPage';
import AdminExternalAccountsGridPage from '../pages/Admin/ExternalAccounts/ExternalAccountsGridPage';
import AdminExternalAccountDetailPage from '../pages/Admin/ExternalAccounts/ExternalAccountDetails/ExternalAccountDetailPage';
import AdPlannerExportPage from '../pages/Contract/AdPlannerExportPage';
import ContractPrintPage from '../pages/Contract/ContractPrintPage';

export enum RouteEnum {
  Dashboard = '/',
  Redeem = '/redeem',
  SignInFailure = '/signInFailure',
  Contract = '/contract',
  AdPlannerExport = '/adPlannerExport',
  ContractId = ':id',
  Admin = '/admin',
  AdminExternalAccounts = '/admin/external-accounts',
  AdminExternalUsers = '/admin/external-accounts/:externalAccountId',
  ContractPrint = '/contract/:id/print',
}

export const PageRouter = () => {
  return (
    <Routes>
      <Route path={RouteEnum.Dashboard} element={<AuthenticatedLayout />}>
        <Route index element={<DashboardPage />} />
        <Route path={RouteEnum.Contract}>
          <Route index element={<ContractPage />} />
          <Route path={RouteEnum.ContractId} element={<ContractPage />} />
        </Route>
        <Route path={RouteEnum.ContractPrint}>
          <Route index element={<ContractPrintPage />} />
        </Route>
        <Route path={RouteEnum.AdPlannerExport}>
          <Route path={RouteEnum.ContractId} element={<AdPlannerExportPage />} />
        </Route>
        <Route path={RouteEnum.Admin}>
          <Route index element={<AdminPage />} />
          <Route path={RouteEnum.AdminExternalAccounts}>
            <Route index element={<AdminExternalAccountsGridPage />} />
            <Route path={RouteEnum.AdminExternalUsers} element={<AdminExternalAccountDetailPage />} />
          </Route>
        </Route>
        <Route path={'*'} element={<NotFound />} />
      </Route>
      <Route path={RouteEnum.SignInFailure} element={<SignInFailure />} />
      <Route path={RouteEnum.Redeem} element={<Redeem />} />
    </Routes>
  );
};
