import { UserType, WorkflowStatus } from '../../../models/enums';

/* 
Use state pattern objects for better separation and avoiding lenghty/complex conditions.
 */
export interface IUIContractPageState {
  showProposedChangesMessageOnce: boolean;
  canSaveOrProposeChange: () => boolean;
  canDeleteContract: boolean;
  showSaveButton: boolean;
  showSaveProposal: boolean;
  showCurrentVersionLabel: boolean;
  showSubmitContractButton: boolean;
  showAcceptContractButton: boolean;
  showTasksButton: boolean;
  showProposedVersionLabel: boolean;
  showDeclineOrKeepProposalButton: boolean;
  showInReviewApproveDropdown: boolean;
  canModifyPricingDuringProposalReview: boolean;
  validatePricingGridOnload: boolean;
}

export function createUIContractPageState(
  workflowStatus: WorkflowStatus | null,
  userType: UserType | undefined,
  withProposal: boolean,
  withProposalReadOnly: boolean
): IUIContractPageState {
  if (userType === undefined) return new NoneContractPageState();

  switch (userType) {
    case UserType.External:
      switch (workflowStatus) {
        case WorkflowStatus.DRAFT:
          return new ExternalInDraftContractPageState();
        case WorkflowStatus.SUBMITTED:
          return new ExternalSubmittedContractPageState();
        case WorkflowStatus.IN_REVIEW:
          return withProposal
            ? withProposalReadOnly
              ? new ExternalInReviewWithProposedChangesReadOnlyContractPageState()
              : new ExternalInReviewWithProposedChangesContractPageState()
            : new ExternalInReviewContractPageState();
        case WorkflowStatus.ACCEPTED:
          return withProposal
            ? withProposalReadOnly
              ? new ExternalAcceptedWithProposedChangesReadOnlyContractPageState()
              : new ExternalAcceptedWithProposedChangesContractPageState()
            : new ExternalAcceptedContractPageState();
        case null:
          return new ExternalCreateNewContractPageState();
      }
      break;
    case UserType.Internal:
      switch (workflowStatus ?? WorkflowStatus.ACCEPTED) {
        case WorkflowStatus.DRAFT:
        case WorkflowStatus.SUBMITTED:
          return new InternalSubmittedContractPageState();
        case WorkflowStatus.IN_REVIEW:
          return withProposal ? new InternalInReviewWithProposedChangesContractPageState() : new InternalInReviewContractPageState();
        case WorkflowStatus.ACCEPTED:
          return withProposal ? new InternalAcceptedWithProposedChangesContractPageState() : new InternalAcceptedContractPageState();
      }
  }
}

abstract class UIContractPageStateBase implements IUIContractPageState {
  showProposedChangesMessageOnce = false;
  canDeleteContract = false;
  showSaveButton = false;
  showSaveProposal = false;
  showCurrentVersionLabel = false;
  showSubmitContractButton = false;
  showAcceptContractButton = false;
  showTasksButton = false;
  showProposedVersionLabel = false;
  showDeclineOrKeepProposalButton = false;
  showInReviewApproveDropdown = false;
  canModifyPricingDuringProposalReview = false;
  validatePricingGridOnload = false;
  canSaveOrProposeChange = () => {
    return this.showSaveButton || this.showSaveProposal;
  };
}

export class NoneContractPageState extends UIContractPageStateBase {}

class ExternalCreateNewContractPageState extends UIContractPageStateBase {
  showSaveButton = true;
}

class ExternalInDraftContractPageState extends UIContractPageStateBase {
  canDeleteContract = true;
  showSaveButton = true;
  showSubmitContractButton = true;
}

class ExternalSubmittedContractPageState extends UIContractPageStateBase {
  canDeleteContract = true;
  showSaveButton = true;
  showTasksButton = true;
}

class ExternalInReviewContractPageState extends UIContractPageStateBase {
  showTasksButton = true;
  showSaveProposal = true;
  showCurrentVersionLabel = true;
}

class ExternalInReviewWithProposedChangesContractPageState extends UIContractPageStateBase {
  showTasksButton = true;
  showProposedVersionLabel = true;
  showSaveProposal = true;
}

class ExternalInReviewWithProposedChangesReadOnlyContractPageState extends UIContractPageStateBase {
  showTasksButton = true;
  showProposedVersionLabel = true;
}
class ExternalAcceptedContractPageState extends UIContractPageStateBase {
  showTasksButton = true;
  showSaveProposal = true;
  showCurrentVersionLabel = true;
}

class ExternalAcceptedWithProposedChangesContractPageState extends UIContractPageStateBase {
  showTasksButton = true;
  showProposedVersionLabel = true;
  showSaveProposal = true;
}

class ExternalAcceptedWithProposedChangesReadOnlyContractPageState extends UIContractPageStateBase {
  showTasksButton = true;
  showProposedVersionLabel = true;
}
class InternalInReviewContractPageState extends UIContractPageStateBase {
  canDeleteContract = true;
  showTasksButton = true;
  showSaveButton = true;
  showAcceptContractButton = true;
}

class InternalInReviewWithProposedChangesContractPageState extends UIContractPageStateBase {
  showProposedChangesMessageOnce = true;
  canDeleteContract = true;
  showTasksButton = true;
  showDeclineOrKeepProposalButton = true;
  showProposedVersionLabel = true;
  canModifyPricingDuringProposalReview = true;

  validatePricingGridOnload = true;
}

class InternalAcceptedContractPageState extends UIContractPageStateBase {
  canDeleteContract = true;
  showTasksButton = true;
  showSaveButton = true;
}

class InternalAcceptedWithProposedChangesContractPageState extends UIContractPageStateBase {
  showProposedChangesMessageOnce = true;
  canDeleteContract = true;
  showTasksButton = true;
  showDeclineOrKeepProposalButton = true;
  showProposedVersionLabel = true;
}
class InternalSubmittedContractPageState extends UIContractPageStateBase {
  canDeleteContract = true;
  showTasksButton = true;
  showInReviewApproveDropdown = true;
}
