import { DataGridCellMessage } from '@dierbergs-markets/react-component-library';
import * as PG from '@dierbergs-markets/react-feature-library';
import { useState, useCallback } from 'react';
import { ValidationIssue } from '../../../../../../classes/ValidationIssue';
import { ValidationScopes, ValidationSeverity, ValidationSource } from '../../../../../../models/enums';
import { useContractValidationStore } from '../../../../../../store/contract/contractValidationStore';

export function usePricingGridInternalValidationHandler() {
  const { setHasInternalPricingGridErrors, setHasInternalPricingGridWarnings, setValidationIssues, validationIssues } = useContractValidationStore();

  const [lastValidation, setLastValidation] = useState<{
    cellMessages: DataGridCellMessage[];
    pricingConfigMessages: PG.PricingConfigMessage[];
  } | null>(null);

  const handleInternalPricingGridValidation = useCallback(
    (cellMessages: DataGridCellMessage[], pricingConfigMessages: PG.PricingConfigMessage[]) => {
      // Skip processing if validation hasn't changed
      if (
        lastValidation &&
        JSON.stringify(cellMessages) === JSON.stringify(lastValidation.cellMessages) &&
        JSON.stringify(pricingConfigMessages) === JSON.stringify(lastValidation.pricingConfigMessages)
      ) {
        return;
      }

      // Update our tracking state
      setLastValidation({ cellMessages, pricingConfigMessages });

      // Update validation flags
      const hasErrors = cellMessages.some((m) => m.isError()) || pricingConfigMessages.some((m) => m.isError());
      const hasWarnings = cellMessages.some((m) => m.isWarning()) || pricingConfigMessages.some((m) => m.isWarning());

      setHasInternalPricingGridErrors(hasErrors);
      setHasInternalPricingGridWarnings(hasWarnings);

      // Process validation issues
      // Create validation issues from cell messages
      const pricingCellValidationIssues = cellMessages.map(
        (message) =>
          new ValidationIssue({
            scope: ValidationScopes.PricingItems,
            field: message.field,
            message: message.message,
            identifier: message.rowId.toString(),
            source: ValidationSource.Ui,
            severity: message.isError() ? ValidationSeverity.Error : ValidationSeverity.Warning,
          })
      );
      // Create validation issues from pricing config messages
      const pricingConfigValidationIssues = pricingConfigMessages.map(
        (message) =>
          new ValidationIssue({
            scope: ValidationScopes.PricingConfigurations,
            message: message.message || 'Pricing configuration error',
            identifier: message.pricingIndex,
            source: ValidationSource.Ui,
            severity: message.isError() ? ValidationSeverity.Error : ValidationSeverity.Warning,
            type: message.type,
          })
      );

      // Get all existing validation issues that aren't from pricing
      const nonPricingIssues = validationIssues.filter(
        (issue) => issue.scope !== ValidationScopes.PricingItems && issue.scope !== ValidationScopes.PricingConfigurations
      );

      // Combine non-pricing issues with new pricing issues
      const allIssues = [...nonPricingIssues, ...pricingCellValidationIssues, ...pricingConfigValidationIssues];

      // Update the store with all issues
      setValidationIssues(allIssues);
    },
    [lastValidation, setHasInternalPricingGridErrors, setHasInternalPricingGridWarnings, setValidationIssues, validationIssues]
  );

  return {
    handleInternalPricingGridValidation,
  };
}
